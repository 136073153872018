import React from "react"
import DataTable from "react-data-table-component"

const customStyles = {
  headCells: {
    style: {
      backgroundColor: "#0E9738",
      color: "white",
    },
  },
}

const PaginatedDataTable = ({
  columns,
  data,
  totalRecords,
  handlePageChange,
  handlePerRowsChange,
}) => {
  return (
    <React.Fragment>
      <DataTable
        pagination={true}
        columns={columns}
        data={data}
        highlightOnHover={true}
        paginationRowsPerPageOptions={[10, 25, 50, 100]}
        paginationTotalRows={totalRecords}
        paginationServer
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        customStyles={customStyles}
      />
    </React.Fragment>
  )
}

export default PaginatedDataTable
