import axios from "axios"
import { deleteKey, getKey } from "../helpers"

const BASEURL = process.env.REACT_APP_BASEURL

const service = axios.create({
  baseURL: BASEURL,
})
service.defaults.headers.common["ContentType"] = "Application/json"

// Add a request interceptor
service.interceptors.request.use(
  async function (config) {
    let data = JSON.parse(await getKey("token"))
    if (data) {
      config.headers["Authorization"] = `Bearer ${data}`
    }

    return config
  },
  function (error) {
    if (error.response) {
      return Promise.reject(error.response)
    } else if (error.request) {
      return Promise.reject(error.request)
    } else {
      return Promise.reject(error.message)
    }
  }
)

// Add a response interceptor
service.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error.response) {
      return Promise.reject(error.response)
    } else if (error.request) {
      return Promise.reject(error.request)
    } else {
      return Promise.reject(error.message)
    }
  }
)

export const get = async (url) => {
  try {
    const { data } = await service.get(url)
    const resolvedData = await Promise.resolve(data)
    if (resolvedData) {
      return resolvedData
    }
  } catch (error) {
    handleRequestError(error);
    return error
  }
}

export const post = async (url, payload) => {
  try {
    const data = await service.post(url, payload)
    const resolvedData = await Promise.resolve(data)
    if (resolvedData) {
      return resolvedData
    }
  } catch (error) {
    handleRequestError(error);
    return error
  }
}

export const patch = async (url, payload, token) => {
  try {
    const data = await service.patch(url, payload)
    const resolvedData = await Promise.resolve(data)
    if (resolvedData) {
      return resolvedData
    }
  } catch (error) {
    handleRequestError(error);
    return error
  }
}

export const put = async (url, payload, token) => {
  try {
    const data = await service.put(url, payload)
    const resolvedData = await Promise.resolve(data)
    if (resolvedData) {
      return resolvedData
    }
  } catch (error) {
    handleRequestError(error);
    return error
  }
}

export const Delete = async (url, payload) => {
  try {
    const data = await service.delete(url)
    const resolvedData = await Promise.resolve(data)
    if (resolvedData) {
      return resolvedData
    }
  } catch (error) {
    handleRequestError(error);
    return error
  }
}

const handleRequestError = (error) => {
  if (error.status === 401) {
    window.location.replace("/login")
  }
}