import React, { useState, useEffect } from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { data } from "common/data/states"

import errorImg from "assets/images/error-img.png"

//i18n
import { withTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { GENERAL } from "api/general"
import Swal from "sweetalert2"

const TrainingRecordsByState = props => {
  const [isErr, setIsErr] = useState(false)
  const [records, setRecords] = useState([])

  useEffect(() => {
    Swal.fire({
      title: "Fetching records...",
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading(Swal.getDenyButton())
      },
    })
    getTrainingRecords;
  }, [])

  const getTrainingRecords = useQuery({
    queryKey: ["getTrainingRecords"],
    queryFn: () => {
      return GENERAL.getApprovedAgents()
    },
    onSuccess: async res => {
      Swal.close();
      console.log("training records by state ::", res)
      if (res?.palliative) {
        setIsErr(false)
        setRecords(res?.palliative.sort((a, b) => a.name.localeCompare(b.name)))
      }
      if (res.status === 500) {
        setIsErr(true)
      }
    },
  })
  //meta title
  document.title = "Agents Registration - eRecruitement"
  function getItemByName(arr, name) {
    return arr.find(item => String(item.name).toLowerCase() === name)?.logo
  }

  return (
    <React.Fragment>
      <div className="page-content">
        {!isErr && (
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={props.t("Training Records")}
              breadcrumbItem={props.t("Training Records By State")}
            />

            <Row>
              {records?.map((item, key) => (
                <Col key={"_col_" + key} md="3">
                  <Card className="mini-stats-wid">
                    <CardBody
                      style={{
                        paddingTop: 40,
                        paddingBottom: 40,
                        paddingLeft: 10,
                        paddingRight: 10,
                      }}
                    >
                      <div className="text-center mb-4">
                        <img
                          src={getItemByName(
                            data,
                            String(item.name).toLowerCase()
                          )}
                          width={100}
                          height={100}
                          style={{ textAlign: "center" }}
                        />

                        <div className="row justify-content-center mt-3">
                          <div className="col-xl-10">
                            <h4 className="text-primary">{item.name}</h4>
                          </div>
                        </div>
                      </div>

                      <hr />
                      <Row
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                      >
                        {/* <Col md="5">
                        <Row>
                          <h5
                            style={{
                              textAlign: "center",
                              fontSize: "1rem",
                              fontWeight: "bold",
                              color: "#000",
                            }}
                          >
                            Threshold
                          </h5>
                          <h6
                            style={{
                              textAlign: "center",
                              fontSize: "0.9rem",
                              fontWeight: "500",
                              color: "#000",
                            }}
                          >
                            1,000
                          </h6>
                        </Row>
                      </Col> */}
                        <Col md="7">
                          <Row>
                            <h5
                              style={{
                                textAlign: "center",
                                fontSize: "1rem",
                                fontWeight: "bold",
                                color: "#000",
                              }}
                            >
                              Agents
                            </h5>
                            <h6
                              style={{
                                textAlign: "center",
                                fontSize: "0.9rem",
                                fontWeight: "500",
                                color: "#000",
                              }}
                            >
                              {item?.approvedUserCount?.toLocaleString()}
                            </h6>
                          </Row>
                        </Col>
                      </Row>
                      <hr />

                      <Row
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Col>
                          <h5
                            style={{
                              textAlign: "center",
                              fontSize: "0.9rem",
                              fontWeight: "500",
                              color: "#000",
                            }}
                          >
                            <Link
                              to={`/training-records-by-lga/${
                                item?.id
                              }/${encodeURIComponent(item?.name)}`}
                            >
                              View LGAs
                            </Link>
                          </h5>
                        </Col>
                        <Col>
                          <h5
                            style={{
                              textAlign: "center",
                              fontSize: "0.9rem",
                              fontWeight: "500",
                              color: "#000",
                            }}
                          >
                            <Link to={`/view-training-records-by-state/${item?.id}`}>
                              View Agents
                            </Link>
                          </h5>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
          </Container>
        )}
        {isErr && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={errorImg} width={500} />
            <p style={{ marginTop: 5, fontSize: 18 }}>
              Error fetching records...
            </p>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(TrainingRecordsByState)
