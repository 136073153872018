const yearData = [
  {
    name: "Series A",
    data: [
      44, 55, 41, 67, 22, 43, 36, 52, 24, 18, 36, 48, 36, 52, 24, 18, 36, 48,
    ],
  },
  {
    name: "Series B",
    data: [
      13, 23, 20, 8, 13, 27, 18, 22, 10, 16, 24, 22, 18, 22, 10, 16, 24, 22,
    ],
  },
  {
    name: "Series C",
    data: [
      11, 17, 15, 15, 21, 14, 11, 18, 17, 12, 20, 18, 11, 18, 17, 12, 20, 18,
    ],
  },
]

const monthData = [
  {
    name: "Series A",
    data: [
      34, 55, 21, 77, 32, 63, 86, 42, 34, 18, 16, 41, 86, 42, 34, 18, 16, 41,
    ],
  },
  {
    name: "Series B",
    data: [
      10, 63, 40, 80, 52, 41, 11, 32, 30, 86, 44, 33, 11, 32, 30, 86, 44, 33,
    ],
  },
  {
    name: "Series C",
    data: [
      11, 17, 15, 85, 21, 14, 80, 58, 17, 12, 20, 18, 80, 58, 17, 12, 20, 18,
    ],
  },
]

const weekData = [
  {
    name: "Series A",
    data: [
      14, 52, 11, 57, 22, 33, 31, 22, 64, 14, 32, 68, 31, 22, 64, 14, 32, 68,
    ],
  },
  {
    name: "Series B",
    data: [
      13, 23, 20, 8, 13, 27, 18, 22, 10, 16, 24, 22, 18, 22, 10, 16, 24, 22,
    ],
  },
  {
    name: "Series C",
    data: [
      11, 17, 15, 15, 34, 55, 21, 18, 17, 12, 20, 18, 21, 18, 17, 12, 20, 18,
    ],
  },
]

const latestTransaction = [
  {
    id: 1,
    orderId: "AGENT-435-6789",
    billingName: "Daniel Miracle Kenneth",
    orderdate: "08165435566",
    total: "Delta",
    paymentStatus: "Asaba",
    methodIcon: "14-04-2023, 10:56",
  },
  {
    id: 2,
    orderId: "AGENT-879-5645",
    billingName: "Aisha Babangida",
    orderdate: "08045343456",
    total: "Borno",
    paymentStatus: "Borno",
    methodIcon: "14-04-2023, 12:23",
  },
  {
    id: 3,
    orderId: "AGENT-898-3245",
    billingName: "Chinedu Michael Sunday",
    orderdate: "07043546576",
    total: "Anambra",
    paymentStatus: "Aguata",
    methodIcon: "14-04-2023, 03:00",
  },
  {
    id: 4,
    orderId: "AGENT-112-6576",
    billingName: "Ugwu Elizabeth Bola",
    orderdate: "09045434567",
    total: "Delta",
    paymentStatus: "Aniocha",
    methodIcon: "13-04-2023, 11:56",
  },
  {
    id: 5,
    orderId: "AGENT-546-6768",
    billingName: "Muhammad Balarabe Isa",
    orderdate: "08032456567",
    total: "Kaduna",
    paymentStatus: "Jaba",
    methodIcon: "13-04-2023, 02:16",
  },
  {
    id: 6,
    orderId: "AGENT-768-9876",
    billingName: "Sambo Sani Goni",
    orderdate: "08156576789",
    total: "Adamawa",
    paymentStatus: "Demsa",
    methodIcon: "13-04-2023, 03:56",
  },
]

const stateCategories = [
  "Ajingi",
  "Kano",
  "Bichi",
  "Fagge",
  "Dala",
  "Makoda",
  "Wudil",
  "Tofa",
  "Takai",
  "Gaya",
  "Kibya",
  "Kabo",
  "Gwarzo",
  "Minjibir",
  "Bebeji",
  "Karaye",
  "Rogo",
  "Rano",
]

const stateData = [
  {
    name: "Series A",
    data: [
      100, 94, 109, 101, 212, 56, 86, 73, 184, 76, 223, 209, 209, 89, 194, 116,
      61, 61,
    ],
  },
]
const regionalDataPieChart = [62, 38]
const regionalDataBarChart = [620, 380]

export {
  yearData,
  monthData,
  weekData,
  latestTransaction,
  stateCategories,
  stateData,
  regionalDataPieChart,
  regionalDataBarChart,
}
