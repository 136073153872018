import { post } from "../services";

export const AUTH = {
  userLogin: async (loginDto) => {
    let data = await post(`auth/login`, loginDto);
    return data;
  },
  requestPasswordOtp: async (loginDto) => {
    let data = await post(`auth/request-password-otp`, loginDto);
    return data;
  },
  validateOtp: async (loginDto) => {
    console.log("validate otp dto :: " + loginDto);
    let data = await post(`auth/verify-admin-otp`, loginDto);
    return data;
  },
  resetPwd: async (loginDto) => {
    let data = await post(`auth/forgot-password`, loginDto);
    return data;
  },
};
