import React, { useEffect, useState } from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import WelcomeComp from "./WelcomeComp"
import BarChart from "./BarChart"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import RegistrationByGender from "./RegistrationByGender"
import { useQuery } from "react-query"
import { GENERAL } from "api/general"
import Swal from "sweetalert2"

const LgaSupervisorDashboard = () => {
  useEffect(() => {
    getDashboardStatsLga
  }, [])

  const [dashboardStats, setDashboardStats] = useState(null)

  const getDashboardStatsLga = useQuery({
    queryKey: ["getDashboardStatsLga"],
    queryFn: () => {
      Swal.fire({
        title: "Loading...",
        showConfirmButton: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading(Swal.getDenyButton())
        },
      })
      return GENERAL.getDashboardStatsLga()
    },
    onSuccess: async res => {
      Swal.close()
      setDashboardStats(res)
    },
  })

  const genderPieChart = [
    dashboardStats?.applicationByGender.male,
    dashboardStats?.applicationByGender.female,
  ]
  const statisticsBarChart = [
    dashboardStats?.totalApprovedAgents,
    dashboardStats?.totalRejectedAgents,
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title="Dashboards"
            breadcrumbItem="Dashboard"
          />

          <Row>
            <Col xl="4">
              <WelcomeComp />
            </Col>
            <Col xl="8">
              <Row>
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      {/* <Link> */}
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">
                            Total Registration Agents
                          </p>
                          <h4 className="mb-0">
                            {dashboardStats?.totalApplicants || 0}
                            {/* {234 - 15} */}
                          </h4>
                        </div>
                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-primary">
                            <i className={"bx bx-copy-alt font-size-24"}></i>
                          </span>
                        </div>
                      </div>
                      {/* </Link> */}
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      {/* <Link to={"/view-approved-agent-registrations-by-lga"}> */}
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">
                            Approved Registration Agents
                          </p>
                          <h4 className="mb-0">
                            {dashboardStats?.totalApprovedAgents || 0}
                            {/* {234 - 45} */}
                          </h4>
                        </div>
                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-primary">
                            <i className={"bx bx-copy-alt font-size-24"}></i>
                          </span>
                        </div>
                      </div>
                      {/* </Link> */}
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      {/* <Link to={"/view-approved-nimc-supervisors-by-lga"}> */}
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">
                            Approved NIMC Supervisors
                          </p>
                          <h4 className="mb-0">
                            {dashboardStats?.totalApprovedNimcSupervisors || 0}
                            {/* {234 - 18} */}
                          </h4>
                        </div>
                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-primary">
                            <i className={"bx bx-copy-alt font-size-24"}></i>
                          </span>
                        </div>
                      </div>
                      {/* </Link> */}
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      {/* <Link to={"/view-pending-agent-registrations-by-lga"}> */}
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">
                            Pending Registration Agents
                          </p>
                          <h4 className="mb-0">
                            {dashboardStats?.totalPendingAgents || 0}
                            {/* {234 - 74} */}
                          </h4>
                        </div>
                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-primary">
                            <i className={"bx bx-copy-alt font-size-24"}></i>
                          </span>
                        </div>
                      </div>
                      {/* </Link> */}
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      {/* <Link to={"/view-declined-agent-registrations-by-lga"}> */}
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">
                            Declined Registration Agents
                          </p>
                          <h4 className="mb-0">
                            {dashboardStats?.totalDeclinedAgents || 0}
                            {/* {234 - 25} */}
                          </h4>
                        </div>
                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-primary">
                            <i className={"bx bx-copy-alt font-size-24"}></i>
                          </span>
                        </div>
                      </div>
                      {/* </Link> */}
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      {/* <Link to={"/view-rejected-agent-registrations"}> */}
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">
                            Rejected Registration Agents
                          </p>
                          <h4 className="mb-0">
                            {dashboardStats?.totalRejectedAgents || 0}
                          </h4>
                        </div>
                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-primary">
                            <i className={"bx bx-copy-alt font-size-24"}></i>
                          </span>
                        </div>
                      </div>
                      {/* </Link> */}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
          <Col xl="12">
              <RegistrationByGender
                dataColors='["--bs-primary", "--bs-success"]'
                periodData={genderPieChart}
              />
              <BarChart
                dataColors='["--bs-primary", "--bs-success"]'
                periodData={statisticsBarChart}
              />
            </Col>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default LgaSupervisorDashboard
