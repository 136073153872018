import PropTypes from "prop-types"
import React, { Fragment, useState } from "react"

//i18n
import { withTranslation } from "react-i18next"
import NimcStaffDashboard from "./NimcStaffDashboard"
import SuperAdminDashboard from "./SuperAdminDashboard"
import StateSupervisorDashboard from "./StateSupervisorDashboard"
import LgaSupervisorDashboard from "./LgaSupervisorDashboard"
import RegionalSupervisorDashboard from "./RegionalSupervisorDashboard"
// import SupervisorDashboard from "./SupervisorDashboard"

const Dashboard = props => {
  const [userRole, setUserRole] = useState(
    JSON.parse(localStorage.getItem("userRole"))
  )
  // const [userRole, setUserRole] = useState("REGIONAL_SUPERVISOR")

  //meta title
  document.title = "Dashboard | Palliative - e-Recruitment Portal"

  return (
    <Fragment>
      {userRole === "SUPER_ADMIN" && <SuperAdminDashboard />}

      {userRole === "NIMC_STAFF" && <NimcStaffDashboard />}
      {userRole === "LGA_SUPERVISOR" && <LgaSupervisorDashboard />}
      {userRole === "STATE_SUPERVISOR" && <StateSupervisorDashboard />}
      {userRole === "REGIONAL_SUPERVISOR" && <RegionalSupervisorDashboard />}

      {/* {(userRole === "REGIONAL_SUPERVISOR" ||
        userRole === "STATE_SUPERVISOR" ||
        userRole === "LGA_SUPERVISOR") && <StateSupervisorDashboard />} */}
    </Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard)
