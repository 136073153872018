import PropTypes from "prop-types"
import React, { useState } from "react"

import {
  Row,
  Col,
  Alert,
  Container,
  Form,
  Input,
  InputGroup,
  FormFeedback,
  Label,
} from "reactstrap"
import CarouselPage from "./CarouselPage"
//redux
import { useSelector, useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { useMutation } from "react-query"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// import images
import logodark from "../../assets/images/coat_of_arms.png"
import logolight from "../../assets/images/coat_of_arms.png"
import { AUTH } from "../../api/auth"

const Login = props => {
  const [showPassword, setshowPassword] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [successMessage, setSuccessMessage] = useState(false)
  //meta title
  document.title = "Login | Palliative - e-Recruitment Portal"

  const dispatch = useDispatch()

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: values => {
      signIn.mutate(values)
    },
  })

  const signIn = useMutation({
    mutationFn: payload => {
      return AUTH.userLogin(payload)
    },

    onSuccess: async res => {
      if (res?.data?.palliative?.statusCode === 403) {
        setErrorMessage(res?.data?.palliative?.message)
        setShowErrorMessage(true)
      }
      if (res?.data?.statusCode === 200) {
        setShowErrorMessage(false)
        setShowSuccessMessage(true)
        setSuccessMessage(res.data.message)
        localStorage.setItem("menu", JSON.stringify(res?.data?.menu))
        localStorage.setItem("authUser", JSON.stringify(res?.data?.user))
        localStorage.setItem("token", JSON.stringify(res?.data?.token))
        localStorage.setItem("userRole", JSON.stringify(res?.data?.user?.role))
        window.location.replace("/dashboard")
      }
    },
    onError: async error => {
      console.log("login error :: ", error)
    },
  })

  const { error } = useSelector(state => ({
    error: state.Login.error,
  }))

  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <CarouselPage />

            <Col xl={3}>
              <div className="auth-full-page-content p-md-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="my-auto ">
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <div
                          className="mb-4 mb-md-5"
                          style={{
                            top: "177px",
                            left: "1520px",
                            width: "99px",
                            height: "99px",
                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                            boxShadow: "0px 3px 6px #00000029",
                            opacity: 1,
                            borderRadius: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "center",
                          }}
                        >
                          <Link to="/dashboard" className="d-block auth-logo">
                            <img
                              src={logodark}
                              alt=""
                              height="75"
                              className="logo-dark-element"
                            />
                            <img
                              src={logolight}
                              alt=""
                              height="75"
                              className="logo-light-element"
                            />
                          </Link>
                        </div>
                      </div>
                      <div>
                        <h3 className="text-primary text-center">
                          Federal Government Of Nigeria
                        </h3>
                        <p
                          className="text-muted"
                          style={{ fontSize: 18, textAlign: "center" }}
                        >
                          Palliative E - Recruitment Dashboard
                        </p>
                      </div>

                      <div className="mt-4">
                        <Form
                          className="form-horizontal"
                          onSubmit={e => {
                            e.preventDefault()
                            validation.handleSubmit()
                            return false
                          }}
                        >
                          {showErrorMessage ? (
                            <Alert color="danger">{errorMessage}</Alert>
                          ) : null}
                          {showSuccessMessage ? (
                            <Alert color="primary">{successMessage}</Alert>
                          ) : null}

                          <div className="mb-3">
                            <Label className="form-label">Email</Label>
                            <Input
                              name="email"
                              className="form-control"
                              placeholder="Enter email"
                              type="email"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email &&
                                validation.errors.email
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.email &&
                            validation.errors.email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.email}
                              </FormFeedback>
                            ) : null}
                          </div>

                          <div className="mb-3">
                            <Label className="form-label">Password</Label>

                            <InputGroup>
                              <Input
                                name="password"
                                value={validation.values.password || ""}
                                type={`${showPassword ? "text" : "password"}`}
                                placeholder="Enter Password"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.password &&
                                  validation.errors.password
                                    ? true
                                    : false
                                }
                              />
                              <div className="input-group-append">
                                <span className="input-group-text">
                                  <i
                                    onClick={() =>
                                      setshowPassword(!showPassword)
                                    }
                                    className={`mdi mdi-${
                                      !showPassword ? "eye-off" : "eye"
                                    }-outline`}
                                  />
                                </span>
                              </div>
                            </InputGroup>
                            {validation.touched.password &&
                            validation.errors.password ? (
                              <FormFeedback type="invalid">
                                {validation.errors.password}
                              </FormFeedback>
                            ) : null}
                          </div>

                          {/* <div className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customControlInline"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customControlInline"
                            >
                              Remember me
                            </label>
                          </div> */}

                          <div className="mt-3 d-grid">
                            {/* <button
                              className="btn btn-primary btn-block"
                              type="submit"
                            >
                              Log In
                            </button> */}
                            {signIn.isLoading && (
                              <button
                                className="btn btn-primary btn-block"
                                type="submit"
                              >
                                Logging In
                              </button>
                            )}
                            {!signIn.isLoading && (
                              <button
                                className="btn btn-primary btn-block"
                                type="submit"
                              >
                                Log In
                              </button>
                            )}
                          </div>

                          <div className="mt-4 text-center">
                            <Link to="/forgot-password" className="text-muted">
                              <i className="mdi mdi-lock me-1" />
                              Forgot your password?
                            </Link>
                          </div>
                        </Form>
                      </div>
                    </div>

                    {/* <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()} Skote. Crafted with{" "}
                        <i className="mdi mdi-heart text-danger"></i> by
                        Themesbrand
                      </p>
                    </div> */}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
