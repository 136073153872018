import React from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import abiaStateLogo from "../../../../src/assets/images/states/Abia_State_Logo.png"

//i18n
import { withTranslation } from "react-i18next"
import { Link } from "react-router-dom"
const RejectedAgentRegistrationsByLga = props => {
  //meta title
  document.title = "Agents Registration - eRecruitement"
  const data = [
    {
      logo: abiaStateLogo,
      name: "Aba North",
    },
    {
      logo: abiaStateLogo,
      name: "Aba South",
    },
    {
      logo: abiaStateLogo,
      name: "Isiala Ngwa North",
    },
    {
      logo: abiaStateLogo,
      name: "Isiala Ngwa South",
    },
    {
      logo: abiaStateLogo,
      name: "Ukwa West",
    },
    {
      logo: abiaStateLogo,
      name: "Ukwa East",
    },
    {
      logo: abiaStateLogo,
      name: "Obingwa",
    },
    {
      logo: abiaStateLogo,
      name: "Ikwuano",
    },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Row>
            <Breadcrumbs
              title={props.t("Rejected Agent Registrations")}
              breadcrumbItem={props.t("Rejected Agent Registrations By LGA")}
              hasBackBtn={true}
            />
          </Row>

          <Row>
            {data.map((item, key) => (
              <Col key={"_col_" + key} md="3">
                <Card className="mini-stats-wid">
                  <CardBody
                    style={{
                      paddingTop: 40,
                      paddingBottom: 40,
                      paddingLeft: 10,
                      paddingRight: 10,
                    }}
                  >
                    <div className="text-center mb-4">
                      <img
                        src={item.logo}
                        width={100}
                        height={100}
                        style={{ textAlign: "center" }}
                      />

                      <div className="row justify-content-center mt-3">
                        <div className="col-xl-10">
                          <h4 className="text-primary">{item.name}</h4>
                        </div>
                      </div>
                    </div>

                    <hr />
                    <Row
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Col md="5">
                        <Row>
                          <h5
                            style={{
                              textAlign: "center",
                              fontSize: "1rem",
                              fontWeight: "bold",
                              color: "#000",
                            }}
                          >
                            Threshold
                          </h5>
                          <h6
                            style={{
                              textAlign: "center",
                              fontSize: "0.9rem",
                              fontWeight: "500",
                              color: "#000",
                            }}
                          >
                            1,000
                          </h6>
                        </Row>
                      </Col>
                      <Col md="7">
                        <Row>
                          <h5
                            style={{
                              textAlign: "center",
                              fontSize: "1rem",
                              fontWeight: "bold",
                              color: "#000",
                            }}
                          >
                            Total Applications
                          </h5>
                          <h6
                            style={{
                              textAlign: "center",
                              fontSize: "0.9rem",
                              fontWeight: "500",
                              color: "#000",
                            }}
                          >
                            6,450
                          </h6>
                        </Row>
                      </Col>
                    </Row>
                    <hr />

                    <Row
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Col>
                        <h5
                          style={{
                            textAlign: "center",
                            fontSize: "0.9rem",
                            fontWeight: "500",
                            color: "#000",
                          }}
                        >
                          <Link to={'/view-rejected-agent-registrations-by-lga'}>
                          View Agents
                          </Link>
                        </h5>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(RejectedAgentRegistrationsByLga)
