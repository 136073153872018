import React from "react"
import PropTypes from "prop-types"
import { Link, useNavigate } from "react-router-dom"
import { Row, Col, BreadcrumbItem } from "reactstrap"

const Breadcrumb = props => {
  const history = useNavigate()
  return (
    <Row>
      <Col className="col-12">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <div className="d-sm-flex align-items-center justify-content-between">
            {props.hasBackBtn && (
              <i
                className="bx bx-left-arrow-circle"
                style={{
                  fontSize: 25,
                  marginRight: 20,
                  color: "red",
                  cursor: "pointer",
                }}
                onClick={() => {
                  history(-1)
                }}
              ></i>
            )}

            <h4 className="mb-sm-0 font-size-18">{props.breadcrumbItem}</h4>
          </div>
          <div className="page-title-right">
            {/* <ol className="breadcrumb m-0">
              <BreadcrumbItem>
                <Link>{props.title}</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>
                <Link>{props.breadcrumbItem}</Link>
              </BreadcrumbItem>
            </ol> */}
          </div>
        </div>
      </Col>
    </Row>
  )
}

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string,
}

export default Breadcrumb
