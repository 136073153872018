import React, { Fragment, useEffect, useState } from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"
import { useQuery } from "react-query"
import WelcomeComp from "./WelcomeComp"
import PieChart from "./PieChart"
import BarChart from "./BarChart"
import LatestTranaction from "./LatestTranaction"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import PropTypes from "prop-types"

import { GENERAL } from "api/general"
import { withTranslation } from "react-i18next"
import StackedColumnChart from "./StackedColumnChart"
import { getChartsData as onGetChartsData } from "store/actions"
import { useSelector, useDispatch } from "react-redux"
import Swal from "sweetalert2"

const SuperAdminDashboard = props => {
  const dispatch = useDispatch()
  const [dashboardStats, setDashboardStats] = useState(null)
  const [statesWithHighestAgents, setStatesWithHighestAgents] = useState([])

  useEffect(() => {
    dispatch(onGetChartsData("yearly"))
  }, [dispatch])

  const getDataPlans = useQuery({
    queryKey: ["getDataPlans"],
    queryFn: () => {
      Swal.fire({
        title: "Loading...",
        showConfirmButton: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading(Swal.getDenyButton())
        },
      })
      return GENERAL.getDashboardStatistics("goal")
    },
    onSuccess: async res => {
      Swal.close()
      setDashboardStats(res)
    },
  })

  const getStateRegistrationData = useQuery({
    queryFn: () => {
      return GENERAL.getStatesWithHighestRegistrationAgents()
    },
    onSuccess: async res => {
      setStatesWithHighestAgents(res)
    },
  })

  const regionalDataPieChart = [
    dashboardStats?.totalApprovedAgents,
    dashboardStats?.totalApprovedNimcSupervisors,
  ]
  const regionalDataBarChart = [
    dashboardStats?.totalApprovedAgents,
    dashboardStats?.totalRejectedAgents,
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          />

          <Row>
            <Col xl="4">
              <WelcomeComp />
              <PieChart
                dataColors='["--bs-primary", "--bs-success"]'
                periodData={regionalDataPieChart}
              />
              <BarChart
                dataColors='["--bs-primary", "--bs-success"]'
                periodData={regionalDataBarChart}
              />
            </Col>
            <Col xl="8">
              <Row>
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">
                            Total Registration Agents
                          </p>
                          <h4 className="mb-0 text-primary">
                            {dashboardStats?.totalApplicants || 0}
                          </h4>
                        </div>
                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-primary">
                            <i className={"bx bx-copy-alt font-size-24"}></i>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <Link to={"/view-approved-agent-registrations-by-lga"}>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              Approved Registration Agents
                            </p>
                            <h4 className="mb-0">
                              {dashboardStats?.totalApprovedAgents || 0}
                            </h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i className={"bx bx-copy-alt font-size-24"}></i>
                            </span>
                          </div>
                        </div>
                      </Link>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <Link to={"/view-approved-nimc-supervisors-by-lga"}>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              Approved NIMC Supervisors
                            </p>
                            <h4 className="mb-0">
                              {dashboardStats?.totalApprovedNimcSupervisors ||
                                0}
                            </h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i className={"bx bx-copy-alt font-size-24"}></i>
                            </span>
                          </div>
                        </div>
                      </Link>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <Link to={"/view-pending-agent-registrations-by-lga"}>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              Pending Registration Agents
                            </p>
                            <h4 className="mb-0">
                              {dashboardStats?.totalPendingAgents || 0}
                            </h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i className={"bx bx-copy-alt font-size-24"}></i>
                            </span>
                          </div>
                        </div>
                      </Link>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <Link to={"/view-declined-agent-registrations-by-lga"}>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              Declined Registration Agents
                            </p>
                            <h4 className="mb-0">
                              {dashboardStats?.totalDeclinedAgents || 0}
                            </h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i className={"bx bx-copy-alt font-size-24"}></i>
                            </span>
                          </div>
                        </div>
                      </Link>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <Link to={"/view-rejected-agent-registrations"}>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              Rejected Registration Agents
                            </p>
                            <h4 className="mb-0">
                              {dashboardStats?.totalRejectedAgents || 0}
                            </h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i className={"bx bx-copy-alt font-size-24"}></i>
                            </span>
                          </div>
                        </div>
                      </Link>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Card>
                <CardBody>
                  <div className="d-sm-flex flex-wrap">
                    <h4 className="card-title mb-4">
                      States with the highest registration agents
                    </h4>
                  </div>
                  <StackedColumnChart
                    periodData={statesWithHighestAgents}
                    dataColors='["--bs-primary", "--bs-primary", "--bs-primary"]'
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <LatestTranaction />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

SuperAdminDashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(SuperAdminDashboard)
