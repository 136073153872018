import React, { useState } from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"
import { GENERAL } from "../../../api/general"
import { useQuery } from "react-query"
import { data } from "common/data/states"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"


//i18n
import { withTranslation } from "react-i18next"
const ApprovedNIMCSupervisorsByState = props => {
  const [records, setRecords] = useState([])
  const getApprovedNimcRecordsByState = useQuery({
    queryKey: ["getApprovedNimcRecordsByState"],
    queryFn: () => {
      return GENERAL.getApprovedNimcRecordsByState()
    },
    onSuccess: async res => {
      console.log("approved nimc agents registrations ::", res)
      if (res?.data?.palliative) {
        setRecords(res.data.palliative?.sort((a, b) => a.name.localeCompare(b.name)))
      }
    },
  })
  //meta title
  document.title = "Agents Registration - eRecruitement";
  function getItemByName(arr, name) {
    return arr.find(item => String(item.name).toLowerCase() === name)?.logo;
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Approved NIMC Supervisors")}
            breadcrumbItem={props.t("Approved NIMC Supervisors By State")}
          />

          <Row>
            {records?.map((item, key) => (
              <Col key={"_col_" + key} md="3">
                <Card className="mini-stats-wid">
                  <CardBody
                    style={{
                      paddingTop: 40,
                      paddingBottom: 40,
                      paddingLeft: 10,
                      paddingRight: 10,
                    }}
                  >
                    <div className="text-center mb-4">
                      <img
                        src={getItemByName(data, String(item.name).toLowerCase())}
                        width={100}
                        height={100}
                        style={{ textAlign: "center" }}
                      />

                      <div className="row justify-content-center mt-3">
                        <div className="col-xl-10">
                          <h4 className="text-primary">{item.name}</h4>
                        </div>
                      </div>
                    </div>

                    <hr />
                    <Row
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Col md="5">
                        <Row>
                          <h5
                            style={{
                              textAlign: "center",
                              fontSize: "1rem",
                              fontWeight: "bold",
                              color: "#000",
                            }}
                          >
                            Threshold
                          </h5>
                          <h6
                            style={{
                              textAlign: "center",
                              fontSize: "0.9rem",
                              fontWeight: "500",
                              color: "#000",
                            }}
                          >
                            {item?.threshold?.toLocaleString()}
                          </h6>
                        </Row>
                      </Col>
                      <Col md="7">
                        <Row>
                          <h5
                            style={{
                              textAlign: "center",
                              fontSize: "1rem",
                              fontWeight: "bold",
                              color: "#000",
                            }}
                          >
                            Total Applications
                          </h5>
                          <h6
                            style={{
                              textAlign: "center",
                              fontSize: "0.9rem",
                              fontWeight: "500",
                              color: "#000",
                            }}
                          >
                            {item?.approvedUserCount?.toLocaleString()}
                          </h6>
                        </Row>
                      </Col>
                    </Row>
                    <hr />

                    <Row
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Col>
                        <h5
                          style={{
                            textAlign: "center",
                            fontSize: "0.9rem",
                            fontWeight: "500",
                            color: "#000",
                          }}
                        >
                          <Link to={`/approved-nimc-supervisors-by-lga/${item?.id}/${item?.name}`}>
                            View LGAs
                          </Link>
                        </h5>
                      </Col>
                      <Col>
                        <h5
                          style={{
                            textAlign: "center",
                            fontSize: "0.9rem",
                            fontWeight: "500",
                            color: "#000",
                          }}
                        >
                          <Link to={`/view-approved-nimc-supervisors-by-state/${item.id}`}>
                            View Supervisors
                          </Link>
                        </h5>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(ApprovedNIMCSupervisorsByState)
