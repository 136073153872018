import React from "react"
import { Progress } from "reactstrap"

const ProgressChart = ({ name, value, color }) => {
  return (
    <React.Fragment>
      <div className="w-100">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <small className="text-capitalize">{name}</small>
          <small>{value} %</small>
        </div>
        <div className="mb-4">
          <Progress
            color={color}
            value={value}
            style={{
              height: "20px",
            }}
          ></Progress>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ProgressChart
