import { get, post } from "../services"
import { getKey } from "../helpers"

export const GENERAL = {
  getDashboardStatistics: async () => {
    let token = await getKey("token")
    let data = await get(`admin/get-dashboard-stats`)
    return data
  },
  getStatesWithHighestRegistrationAgents: async () => {
    let data = await get(`admin/states-with-highest-agent-registrations`)
    return data
  },
  getDashboardStatsLga: async () => {
    let data = await get(`admin/get-dashboard-stats-per-lga`)
    return data
  },
  getDashboardStatsState: async () => {
    let data = await get(`admin/get-dashboard-stats-per-state`)
    return data
  },
  getDashboardStatsRegional: async () => {
    let data = await get(`admin/get-dashboard-stats-per-region`)
    return data
  },
  getDashboardStatisticsNimc: async () => {
    let data = await get(`admin/get-dashboard-stats-per-lga`)
    return data
  },
  getApprovedAgents: async () => {
    console.log("calling approved agents endpoint")
    let data = await get(`admin/get-approve-agent-states`)

    return data
  },
  getApprovedAgentsByStateId: async id => {
    let data = await post(`admin/get-approve-agent-lga`, { state_id: +id })

    return data
  },
  getApprovedNimcRecordsByState: async () => {
    let data = await get(`admin/get-approve-nimc-states`)

    return data
  },
  getApprovedNimcRecordsByStateId: async id => {
    let data = await post(`admin/get-approve-nimc-lga`, { state_id: +id })

    return data
  },
  getApprovedNIMCSupervisorsRecordsByState: async ({ page, pageSize, searchKey }) => {
    let data = await get(`admin/get-nimc-approved-state-list?page=${page}&pageSize=${pageSize}&searchKey=${searchKey}`)
    return data
  },
  getTrainingRecordsByStateId: async ({ page, pageSize, searchKey, stateId })  => {
    let data = await get(`admin/get-attendance-list?page=${page}&pageSize=${pageSize}&searchKey=${searchKey}&stateId=${stateId}`)
    return data
  },
  getTrainingRecordsByLgaId: async ({ page, pageSize, searchKey, lgaId }) => {
    let data = await get(`admin/get-attendance-list-lga?page=${page}&pageSize=${pageSize}&searchKey=${searchKey}&lgaId=${lgaId}`)
    return data
  },
  getApprovedRecordsByState: async ({ page, pageSize, searchKey }) => {
    let data = await get(`admin/get-approved-state-list?page=${page}&pageSize=${pageSize}&searchKey=${searchKey}`)
    return data
  },
  getApprovedRecordsByStateById: async ({ page, pageSize, searchKey, stateId }) => {
    let data = await get(`admin/get-approved-state-list-by-id?page=${page}&pageSize=${pageSize}&searchKey=${searchKey}&stateId=${stateId}`)
    return data
  },
  getPendingRecordsByState: async ({ page, pageSize, searchKey }) => {
    let data = await get(`admin/get-pending-state-list?page=${page}&pageSize=${pageSize}&searchKey=${searchKey}`)
    return data
  },
  getDeclinedRecordsByState: async ({ page, pageSize, searchKey }) => {
    let data = await get(`admin/get-declined-state-list?page=${page}&pageSize=${pageSize}&searchKey=${searchKey}`)
    return data
  },
  getRejectedRecordsByState: async ({ page, pageSize, searchKey }) => {
    let data = await get(`admin/get-rejected-state-list?page=${page}&pageSize=${pageSize}&searchKey=${searchKey}`)
    return data
  },
  getRecordsByLgaId: async ({ page, pageSize, searchKey, lgaId }) => {
    let data = await get(`admin/get-lga-list?page=${page}&pageSize=${pageSize}&searchKey=${searchKey}`)
    return data
  },
  getApprovedAgentListPerLga: async ({ page, pageSize, searchKey })  => {
    let data = await get(`admin/get-approved-agent-list-per-lga?page=${page}&pageSize=${pageSize}&searchKey=${searchKey}`)
    return data
  },
  getRecordByNpcId: async payload => {
    let data = await post(`admin/get-single-agent`, payload)

    return data
  },
  getStates: async () => {
    let data = await get(`resources/states`)

    return data
  },
  getLgas: async state_id => {
    let data = await get(`resources/lga?stateId=${state_id}`)

    return data
  },
  createUser: async payload => {
    let data = await post(`auth/create-user`, payload)

    return data
  },
  generateCode: async payload => {
    let data = await post(`admin/generate-agent-codes`, payload)
    return data
  },
  retriveGeneratedCodes: async payload => {
    let data = await post(`admin/retrive-generated-lga`, payload)
    return data
  },
  retriveGeneratedCodesByLga: async payload => {
    let data = await post(`admin/retrive-codes-per-lga`, payload)
    return data
  },
  markAttendance: async payload => {
    let data = await post(`admin/mark-attendance`, payload)
    return data
  },
  deleteAgentFn: async payload => {
    let data = await post(`admin/delete-agent`, payload)
    return data
  },
  ninVerifyFn: async payload => {
    let data = await post(`nin/verify`, payload)
    return data
  },
  changePwdFn: async payload => {
    let data = await post(`admin/change-password`, payload)
    return data
  },
  getAdminListFn: async ({ page, pageSize, searchKey }) => {
    const response = await get(
      `/admin/get-admins-list?page=${page}&pageSize=${pageSize}&searchKey=${searchKey}`
    )
    return response
  },
  getApprovedRecordsList: async ({ page, pageSize, searchKey }) => {
    let data = await get(
      `admin/get-approved-agent-list?page=${page}&pageSize=${pageSize}&searchKey=${searchKey}`
    )
    return data
  },
  getApprovedNimcRecordsList: async ({ page, pageSize, searchKey }) => {
    let data = await get(
      `admin/get-approved-nimc-list?page=${page}&pageSize=${pageSize}&searchKey=${searchKey}`
    )
    return data
  },
  getPendingRecordsList: async ({ page, pageSize, searchKey }) => {
    let data = await get(
      `admin/get-pending-list?page=${page}&pageSize=${pageSize}&searchKey=${searchKey}`
    )
    return data
  },
  getPendingRecordsListNimc: async ({ page, pageSize, searchKey }) => {
    let data = await get(`admin/get-pending-list-per-lga?page=${page}&pageSize=${pageSize}&searchKey=${searchKey}`)
    return data
  },
  getDeclinedRecordsList: async ({ page, pageSize, searchKey }) => {
    let data = await get(
      `admin/get-declined-agent-list?page=${page}&pageSize=${pageSize}&searchKey=${searchKey}`
    )
    return data
  },
  getDeclinedRecordsListNimc: async ({ page, pageSize, searchKey }) => {
    let data = await get(`admin/get-declined-agent-list-per-lga?page=${page}&pageSize=${pageSize}&searchKey=${searchKey}`)
    return data
  },
  getRejectedLGARecordsList: async ({ page, pageSize, searchKey }) => {
    let data = await get(
      `admin/get-rejected-agent-list-per-lga?page=${page}&pageSize=${pageSize}&searchKey=${searchKey}`
    )
    return data
  },
  getRejectedRecordsList: async ({ page, pageSize, searchKey }) => {
    let data = await get(
      `admin/get-rejected-list?page=${page}&pageSize=${pageSize}&searchKey=${searchKey}`
    )
    return data
  },
  searchAgent: async payload => {
    let data = await post(`admin/search-agent`, payload)
    return data
  },
}
