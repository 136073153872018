import React, { Fragment, useState, useEffect } from "react"
import { Container, Col, Row, Button, Card, CardBody } from "reactstrap"
import { useMutation } from "react-query"
import { GENERAL } from "../../../api/general"
import { useNavigate } from "react-router-dom"
import { useAsyncDebounce } from "react-table"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
//i18n
import { withTranslation } from "react-i18next"
import Swal from "sweetalert2"
import PaginatedDataTable from "components/PaginatedDataTable"

const ViewApprovedNIMCSupervisorsByLga = props => {
  const navigate = useNavigate()
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [data, setData] = useState([])
  const [totalRecords, setTotalRecords] = useState(0)
  const [filter, setFilter] = useState("")

  const [value, setValue] = React.useState("")
  const onChange = useAsyncDebounce(value => {
    setFiilter(value || undefined)
  }, 200)

  const fetchRecords = async (page, pageSize, filter) => {
    Swal.fire({
      title: "Fetching records...",
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading(Swal.getDenyButton())
      },
    })

    try {
      const response = await getApprovedNimcRecordsList.mutateAsync({
        page,
        pageSize,
        searchKey: filter ?? "",
      })
      Swal.close()
      setData(response.palliative)
      setTotalRecords(response.totalRecords)
    } catch (error) {
      Swal.fire({
        title: "Error fetching records",
        text: error.message,
        icon: "error",
      })
    }
  }

  useEffect(() => {
    fetchRecords(page, pageSize, filter)
  }, [page, pageSize])

  const getApprovedNimcRecordsList = useMutation({
    mutationFn: async ({ page, pageSize, searchKey }) => {
      return GENERAL.getApprovedNimcRecordsList({ page, pageSize, searchKey })
    },
    onError: error => {
      Swal.fire("Error fetching records", error.message, "error")
    },
  })

  const handlePageChange = page => {
    setPage(page)
  }

  const handlePerRowsChange = (newPageSize, page) => {
    setPageSize(newPageSize)
    setPage(1) // Reset to first page whenever page size changes
  }

  const startIndex = (page - 1) * pageSize

  const handleSearchFn = async () => {
    Swal.fire({
      title: "Searching records...",
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading(Swal.getDenyButton())
      },
    })
    setPage(1) // Reset to first page when searching
    await fetchRecords(1, pageSize, filter)
  }

  //meta title
  document.title = "Agents Registration - eRecruitement"

  const columns = [
    {
      name: "#",
      sortable: true,
      selector: (row, index) => startIndex + index + 1,
      width: "4rem",
    },
    {
      name: "Application ID",
      selector: row => row.npc_id,
      sortable: true,
    },
    {
      name: "First Name",
      sortable: true,
      selector: row => row.first_name,
    },
    {
      name: "Last Name",
      sortable: true,
      selector: row => row.last_name,
    },
    {
      name: "Phone No.",
      sortable: true,
      selector: row => row.phone,
    },
    {
      name: "State of Residence",
      sortable: true,
      selector: row => row.state_resid,
    },
    {
      name: "LGA of Residence",
      sortable: true,
      selector: row => row.lga_resid,
    },
    {
      name: "Date Created",
      sortable: true,
      selector: row => new Date(row.created_at).toLocaleDateString(),
    },
    {
      name: "View Details",
      disableFilters: true,
      cell: row => (
        <Button
          type="button"
          color="primary"
          className="btn-sm btn-rounded"
          onClick={() => gotoProfile(row)}
        >
          View Details
        </Button>
      ),
    },
  ]

  const gotoProfile = row => {
    const npc_id = row?.npc_id
    if (!npc_id) {
      return Swal.fire("Process failed", "User not found", "error")
    }
    navigate(`/profile/${npc_id}`)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Row>
            <Breadcrumbs
              title={props.t("Approved NIMC Supervisors")}
              breadcrumbItem={props.t("Total Approved NIMC Supervisors")}
              hasBackBtn={true}
            />
          </Row>

          <Card>
            <CardBody>
              <div className="mb-4 h4 card-title">
                All Registration NIMC Supervisors
              </div>
              <Fragment>
                <Row className="mb-2">
                  <React.Fragment>
                    <Col md={6}>
                      <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
                        <div
                          className="position-relative"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <label
                            htmlFor="search-bar-0"
                            className="search-label"
                          >
                            <span id="search-bar-0-label" className="sr-only">
                              Search this table
                            </span>
                            <input
                              onChange={e => setFilter(e.target.value)}
                              id="search-bar-0"
                              type="text"
                              className="form-control"
                              placeholder={`Search table`}
                              value={filter || ""}
                            />
                          </label>
                          <i
                            className="bx bx-search-alt search-icon"
                            style={{ cursor: "pointer" }}
                            onClick={handleSearchFn}
                          ></i>
                          <div
                            className="d-inline-block"
                            style={{ marginLeft: 5, marginBottom: 8 }}
                          >
                            <Button
                              type="button"
                              color="primary"
                              className="btn-md btn-rounded"
                              onClick={handleSearchFn}
                            >
                              Search
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </React.Fragment>
                </Row>

                <PaginatedDataTable
                  columns={columns}
                  data={data}
                  totalRecords={totalRecords}
                  handlePageChange={handlePageChange}
                  handlePerRowsChange={handlePerRowsChange}
                />
              </Fragment>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(ViewApprovedNIMCSupervisorsByLga)
