import React, { useState, useEffect, useMemo, Fragment } from "react"
import {
  Container,
  Col,
  Row,
  Button,
  Card,
  CardBody,
  Input,
  Table,
  Modal,
  ModalBody,
  Form,
  Label,
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import { useTable } from "react-table"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useQuery, useMutation } from "react-query"
import { GENERAL } from "api/general"
import Swal from "sweetalert2"

//i18n
import { withTranslation } from "react-i18next"

import * as xlsx from "xlsx"
import saveAs from "file-saver"

const ViewCodeModal = ({ record, passedValue, callMethodFromFirst }) => {
  const [reason, setReason] = useState(null)
  const [data, setRecords] = useState([])
  const customPageSize = 6

  useEffect(() => {
    console.log("getting codes...", record)
    if (record) {
      retriveGeneratedCodesByLga.mutate({ lga_id: `${record?.id}` })
    }
  }, [record])

  const retriveGeneratedCodesByLga = useMutation({
    mutationFn: payload => {
      return GENERAL.retriveGeneratedCodesByLga(payload)
    },
    onSuccess: async res => {
      Swal.close()
      console.log("retrieve generated codes by lga response ::", res)
      if (res?.data?.palliative.status == 1) {
        setRecords(res?.data?.palliative?.codesList)
      }
    },
  })

  const columns = useMemo(
    () => [
      {
        Header: "Code",
        accessor: "npc_id",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <BoldFormatText {...cellProps} />
        },
      },
      {
        Header: "Test Assessment Status",
        accessor: "test_passed",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FormatBoolean {...cellProps} />
        },
      },
      {
        Header: "Delete",
        accessor: "view",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FormatDelete {...cellProps} />
        },
      },
    ],
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable({
    columns,
    data,
    initialState: {
      pageIndex: 0,
      pageSize: customPageSize,
      sortBy: [
        {
          desc: true,
        },
      ],
    },
  })

  const BoldFormatText = cell => {
    return (
      <Link to="#" className="text-body fw-bold">
        {cell.value ? cell.value : ""}
      </Link>
    )
  }

  const FormatText = cell => {
    return cell.value ? cell.value : ""
  }

  const FormatBoolean = cell => {
    return cell.value == "false" ? (
      <p className="badge bg-warning mb-1 p-2">Pending</p>
    ) : cell.value == "1" ? (
      <p className="badge bg-primary mb-1 p-2">Passed</p>
    ) : (
      <p className="badge bg-danger mb-1 p-2">Failed</p>
    )
  }

  const handleDeleteAgentFn = applicationId => {
    console.log("applicationId :: ", applicationId)
    // if (reason) {
    // setShow(false)
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete code!",
    }).then(result => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Enter a reason",
          input: "text",
          inputAttributes: {
            autocapitalize: "off",
          },
          showCancelButton: true,
          confirmButtonText: "Proceed",
          showLoaderOnConfirm: true,
          preConfirm: async login => {
            return login
          },
          allowOutsideClick: () => !Swal.isLoading(),
        }).then(result => {
          console.log("this is the result value :: ", result)
          if (result.isConfirmed) {
            // Swal.fire({
            //   title: `${result.value}'s avatar`
            // })

            Swal.fire({
              title: "Deleting code...",
              showConfirmButton: false,
              allowEscapeKey: false,
              allowOutsideClick: false,
              didOpen: () => {
                Swal.showLoading(Swal.getDenyButton())
              },
            })

            const deleteAgentDto = {
              code: applicationId,
              comment: result.value,
            }

            console.log("deleteAgentDto :: ", deleteAgentDto)
            // call delete agent mutation
            deleteAgentFn.mutate(deleteAgentDto)
          }
        })
      }
    })
    // } else {
    //   Swal.fire("Process Failed", "Reason cannot be empty", "error")
    // }
  }

  const deleteAgentFn = useMutation({
    mutationFn: payload => {
      return GENERAL.deleteAgentFn(payload)
    },
    onSuccess: async res => {
      Swal.close()
      console.log("delete-agent response ::", res)
      if (res?.data?.palliative.statusCode == 400) {
        Swal.fire("Process Failed!", res?.data?.palliative.message, "error")
        showModal()
      }
      if (res?.data?.palliative.statusCode == 200) {
        Swal.fire(
          "Process Successful!",
          res?.data?.palliative.message,
          "success"
        ).then(res => {
          if (res.isConfirmed) {
            // history(-1)
            retriveGeneratedCodesByLga.mutate({ lga_id: `${record?.id}` })
          }
        })
      }
    },
  })

  const FormatDelete = cell => {
    console.log("this is the record to delete :: ", cell.cell.row.values)
    const cellValue = cell.cell.row.values.test_passed
    const applicationId = cell.cell.row.values.npc_id
    return cellValue == "false" ? (
      <div
        onClick={() => handleDeleteAgentFn(applicationId)}
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <i className="bx bx-trash text-danger"></i>
        <p
          style={{ marginLeft: 5, marginTop: 2, marginBottom: 0, color: "red" }}
        >
          Delete
        </p>
      </div>
    ) : cellValue == "1" ? (
      ""
    ) : (
      <div
        onClick={() => handleDeleteAgentFn(applicationId)}
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <i className="bx bx-trash text-danger"></i>
        <p
          style={{ marginLeft: 5, marginTop: 2, marginBottom: 0, color: "red" }}
        >
          Delete
        </p>
      </div>
    )
  }

  return (
    <Modal size="xl" isOpen={passedValue} centered={true}>
      <ModalBody className="py-3 px-5">
        <Fragment>
          <Row className="mb-2">
            <Col sm="12">
              <div
                className="text-sm-center"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  background: "green",
                  padding: 10,
                }}
              >
                <p style={{ color: "white", fontSize: 18, fontWeight: "bold" }}>
                  State: {record?.state}
                </p>
                <p style={{ color: "white", fontSize: 18, fontWeight: "bold" }}>
                  LGA: {record?.lga}
                </p>
                <p style={{ color: "white", fontSize: 18, fontWeight: "bold" }}>
                  Threshold: 35
                </p>
                <p style={{ color: "white", fontSize: 18, fontWeight: "bold" }}>
                  Count: {data.length}
                </p>
              </div>
            </Col>
          </Row>

          <div className="table-responsive react-table">
            <Table bordered hover {...getTableProps()}>
              <thead className="table-primary table-nowrap">
                {headerGroups.map(headerGroup => (
                  <tr
                    key={headerGroup.id}
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map(column => (
                      <th key={column.id} {...column.getHeaderProps()}>
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                  prepareRow(row)
                  return (
                    <tr key={i} {...row.getRowProps()}>
                      {row.cells.map(cell => {
                        return (
                          <td key={cell.id} {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </div>

          <Row>
            <Col xl={12} style={{ marginTop: 40 }}>
              <button
                type="submit"
                style={{ marginLeft: 9 }}
                className="btn btn-danger w-100"
                onClick={callMethodFromFirst}
              >
                CANCEL
              </button>
            </Col>
          </Row>
        </Fragment>
      </ModalBody>
    </Modal>
  )
}

export { ViewCodeModal }

const GenerateCode = props => {
  const [show, setShow] = useState(false)
  const [show2, setShow2] = useState(false)
  const [filterByState, setFilterByState] = useState(null)

  const [data, setGeneratedCodes] = useState([])
  const [states, setStates] = useState([])
  const [lgas, setLgas] = useState([])
  const [selectedState, setSelectedState] = useState("")
  const [selectedLga, setSelectedLga] = useState("")
  const [selectedRecordId, setSelectedRecordId] = useState(null)
  const navigate = useNavigate()
  //meta title
  document.title = "Agents Registration - eRecruitement"
  const customPageSize = 6

  useEffect(() => {
    Swal.fire({
      title: "Fetching records...",
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading(Swal.getDenyButton())
      },
    })
    const recordsDto = {
      // state_id: 1,
    }
    retriveGeneratedCodes.mutate(recordsDto)
  }, [])

  const retriveGeneratedCodes = useMutation({
    mutationFn: payload => {
      return GENERAL.retriveGeneratedCodes(payload)
    },
    onSuccess: async res => {
      Swal.close()
      console.log("retrieve generated codes response ::", res)
      if (res?.data?.palliative) {
        setGeneratedCodes(res.data.palliative)
      }
    },
  })

  const getStates = useQuery({
    queryKey: ["getStates"],
    queryFn: () => {
      return GENERAL.getStates()
    },
    onSuccess: async res => {
      console.log("get all statest response ::", res)
      if (res?.palliative) {
        setStates(res.palliative)
      }
    },
  })

  const getLgas = useMutation({
    mutationFn: payload => {
      return GENERAL.getLgas(payload.state_id)
    },
    onSuccess: async res => {
      Swal.close()
      console.log("these are the lgas ::", res)
      // const fixedWallets = res.result
      if (res?.palliative) {
        setLgas(res.palliative)
      }
    },
  })

  const showModal = () => {
    setShow(true)
  }
  const showModal2 = evt => {
    console.log("selected record :: ", evt)
    const selectedRecord = {
      id: evt?.row?.original?.id,
      state: evt?.row?.values?.state_name,
      lga: evt?.row?.values?.lga_name,
    }
    setSelectedRecordId(selectedRecord)
    setShow2(true)
  }

  const columns = useMemo(
    () => [
      {
        Header: "S/N",
        accessor: "index",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <FormatIndex {...cellProps} />
        },
      },
      {
        Header: "State",
        accessor: "state_name",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <BoldFormatText {...cellProps} />
        },
      },
      {
        Header: "LGA",
        accessor: "lga_name",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <FormatText {...cellProps} />
        },
      },
      {
        Header: "",
        accessor: "view",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              onClick={() => showModal2(cellProps)}
            >
              View Generated Codes
            </Button>
          )
        },
      },
      {
        Header: "",
        accessor: "paymentStatus",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return (
            <div
              onClick={() => exportToXlsxFn(cellProps)}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAQdJREFUSEvV1ssRgjAQANBNOES7wCZEb1AHzKiVIJVwgTrghtiEdoEZlThBmUFwyIbPQa4k+8juJoHAzA+ZOT50AC8rbDCID4LYCjyILHZUfWAHcHN+IQCmauLnvRLpriDnAhE8AAAfgwwCIosRL+cyPUpkMCC/HoOMAjDIaKCNyPQ1azgI6GuCvwMCAbBr7ptJV1C1a1bYgtKwRiYHZD32WWHeKU0kog20J/QV2D3xMN6wg1YX6QDe+ZZE64WjBWBaUqboYZBQnsDaKVIB1fFOaVKPmxSAsnSawSUyLfBjeUpA88L5IgTANbbYqr/IrY2DuHzeQ4hI4SmCaLtMewF0QOTA2f8qXohmkRn9bOp8AAAAAElFTkSuQmCC" />
              <p style={{ marginLeft: 5, marginTop: 4, marginBottom: 0 }}>
                Export Agent Codes
              </p>
            </div>
          )
        },
      },
    ],
    []
  )

  const FormatIndex = cell => {
    console.log("row :: ", cell)
    const index = +cell?.row?.id + 1
    return index
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable({
    columns,
    data,
    initialState: {
      pageIndex: 0,
      pageSize: customPageSize,
      sortBy: [
        {
          desc: true,
        },
      ],
    },
  })

  const BoldFormatText = cell => {
    return (
      <Link to="#" className="text-body fw-bold">
        {cell.value ? cell.value : ""}
      </Link>
    )
  }

  const FormatText = cell => {
    return cell.value ? cell.value : ""
  }

  const closeModal = () => {
    setShow(false)
  }

  const closeShowModal = () => {
    setShow2(false)
  }

  const handleSelectState = evt => {
    console.log("evt :: ", evt.target.value)
    setSelectedState(evt.target.value)
    Swal.fire({
      title: "Fetching LGAs...",
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading(Swal.getDenyButton())
      },
    })
    const getLgaDto = {
      state_id: evt.target.value,
    }
    console.log("getLgaDto :: ", getLgaDto)
    getLgas.mutate(getLgaDto)
  }

  const handleSelectLga = evt => {
    console.log("evt :: ", evt.target.value)
    setSelectedLga(evt.target.value)
  }

  const generateCode = useMutation({
    mutationFn: payload => {
      return GENERAL.generateCode(payload)
    },
    onSuccess: async res => {
      Swal.close()
      console.log("generate codes response ::", res)
      if (res?.data?.palliative.status == 400) {
        Swal.fire("Process Failed!", res?.data?.palliative.msg, "error")
        showModal()
      }
      if (res?.data?.palliative.status == 200) {
        Swal.fire(
          "Process Successful!",
          res?.data?.palliative.msg,
          "success"
        ).then(res => {
          if (res.isConfirmed) {
            Swal.fire({
              title: "Fetching records...",
              showConfirmButton: false,
              allowEscapeKey: false,
              allowOutsideClick: false,
              didOpen: () => {
                Swal.showLoading(Swal.getDenyButton())
              },
            })
            retriveGeneratedCodes.mutate({})
          }
        })
      }
    },
  })

  const handleGenerateCode = () => {
    if (selectedState == "" && selectedLga == "") {
      Swal.fire("Process Failed!", "State is required.", "error")
    }
    if (selectedState !== "") {
      if (selectedLga == "") {
        Swal.fire("Process Failed!", "LGA is required.", "error")
      }
    }
    if (selectedState != "" && selectedLga != "") {
      closeModal()
      const generateCodeDto = {
        state_id: selectedState,
        lga_id: selectedLga,
      }

      console.log("generateCodeDto :: ", generateCodeDto)
      Swal.fire({
        title: "Generating codes...",
        showConfirmButton: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading(Swal.getDenyButton())
        },
      })
      generateCode.mutate(generateCodeDto)
    }
  }

  const exportExcel = dataSheets => {
    const newData = dataSheets.map(item => {
      const { npc_id, test_passed } = item

      return {
        agent_id: npc_id, // Assuming 'index' is the mapped property for 'id'
        assessment_status:
          test_passed == "false"
            ? "Pending"
            : test_passed == "1"
            ? "Passed"
            : "Failed",
      }
    })

    console.log("newData :: ", newData)
    const worksheet = xlsx.utils.json_to_sheet(newData)
    const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] }
    const excelBuffer = xlsx.write(workbook, {
      bookType: "xlsx",
      type: "array",
    })
    saveAsExcelFile(excelBuffer, "Agent Codes")
  }

  const saveAsExcelFile = (buffer, fileName) => {
    let EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
    let EXCEL_EXTENSION = ".xlsx"
    const data = new Blob([buffer], {
      type: EXCEL_TYPE,
    })
    saveAs(data, fileName + "export" + new Date().getTime() + EXCEL_EXTENSION)
    Swal.close()
  }

  const exportToXlsxFn = record => {
    console.log("record :: ", record?.cell?.row?.original?.id)
    const lgaId = record?.cell?.row?.original?.id;
    Swal.fire({
      title: "Fetcing codes...",
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading(Swal.getDenyButton())
      },
    })

    if (lgaId) {
      const getCodeDto = { lga_id: `${lgaId}` }
      retriveGeneratedCodesByLga.mutate(getCodeDto)
    }
  }

  const retriveGeneratedCodesByLga = useMutation({
    mutationFn: payload => {
      return GENERAL.retriveGeneratedCodesByLga(payload)
    },
    onSuccess: async res => {
      Swal.close()
      console.log(
        "retrieve generated codes by lga response ::::",
        res?.data?.palliative
      )
      if (res?.data?.palliative.status == 1) {
        // setRecords(res?.data?.palliative?.codesList)
        const codes = res?.data?.palliative?.codesList
        // Export record
        Swal.fire({
          title: "Exporting codes...",
          showConfirmButton: false,
          allowEscapeKey: false,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading(Swal.getDenyButton())
          },
        })

        exportExcel(codes)
      }
    },
  })

  const handleFilterState = evt => {
    Swal.fire({
      title: "Fetching records...",
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading(Swal.getDenyButton())
      },
    })
    console.log("filtered state :: ", evt.target.value)
    const state_id = evt.target.value
    setSelectedState(evt.target.value)
    if (state_id == 0) {
      const filterDto = {}

      console.log("filterDto :: ", filterDto)
      retriveGeneratedCodes.mutate(filterDto)
    }
    if (state_id > 0) {
      const filterDto = {
        state_id: +state_id,
      }
      console.log("filterDto :: ", filterDto)
      retriveGeneratedCodes.mutate(filterDto)
    }
  }

  return (
    <React.Fragment>
      {/* generate code modal */}
      <Modal size="lg" isOpen={show} centered={true}>
        <ModalBody className="py-3 px-5">
          <Row style={{ paddingTop: "25px", paddingBottom: "25px" }}>
            <Col lg={12} style={{ marginTop: "10px", marginBottom: "10px" }}>
              <div className="">
                <h5>NEW CODES</h5>
              </div>
            </Col>
            <Form>
              <Row>
                <Col md={6}>
                  <div className="mb-3">
                    <Label htmlFor="formrow-email-Input">
                      State <span className="text-danger">*</span>
                    </Label>
                    <div className="col-md-12">
                      <select
                        onChange={handleSelectState}
                        className="form-control"
                      >
                        <option>Select</option>
                        {states?.map(state => (
                          <option key={state.id} value={state.id}>
                            {state.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <Label htmlFor="formrow-password-Input">
                      LGA <span className="text-danger">*</span>
                    </Label>
                    <div className="col-md-12">
                      <select
                        onChange={handleSelectLga}
                        className="form-control"
                      >
                        <option>Select</option>
                        {lgas?.map(lga => (
                          <option key={lga.id} value={lga.id}>
                            {lga.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </Col>
              </Row>
            </Form>
          </Row>
          <Row>
            <Col xl={6}>
              <button
                type="submit"
                className="btn btn-info w-100"
                onClick={handleGenerateCode}
              >
                GENERATE CODE
              </button>
            </Col>

            <Col xl={6}>
              <button
                type="submit"
                style={{ marginLeft: 9 }}
                className="btn btn-danger w-100"
                onClick={() => closeModal()}
              >
                CANCEL
              </button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      <ViewCodeModal
        record={selectedRecordId}
        passedValue={show2}
        callMethodFromFirst={closeShowModal}
      />

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Row>
            <Breadcrumbs
              title={props.t("Generate Code")}
              breadcrumbItem={props.t("Generate Code")}
              hasBackBtn={true}
            />
          </Row>

          <Card>
            <CardBody>
              <div className="mb-4 h4 card-title">Agent Codes</div>
              <Fragment>
                <Row className="mb-2">
                  <Col md={3}>
                    <select
                      onChange={handleFilterState}
                      className="form-select"
                      value={filterByState}
                    >
                      <option key="0" value="0">
                        All States
                      </option>
                      {states?.map(state => (
                        <option key={state.id} value={state.id}>
                          {state.name}
                        </option>
                      ))}
                    </select>
                  </Col>
                  <Col md={6}></Col>

                  <Col md="3">
                    <div className="text-sm-end">
                      <Button
                        type="button"
                        color="primary"
                        className="btn mb-2 me-2"
                        onClick={() => showModal()}
                      >
                        Generate Code
                      </Button>
                    </div>
                  </Col>
                </Row>

                <div className="table-responsive react-table">
                  <Table bordered hover {...getTableProps()}>
                    <thead className="table-primary table-nowrap">
                      {headerGroups.map(headerGroup => (
                        <tr
                          key={headerGroup.id}
                          {...headerGroup.getHeaderGroupProps()}
                        >
                          {headerGroup.headers.map(column => (
                            <th key={column.id} {...column.getHeaderProps()}>
                              {column.render("Header")}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {rows.map((row, i) => {
                        prepareRow(row)
                        return (
                          <tr key={i} {...row.getRowProps()}>
                            {row.cells.map(cell => {
                              return (
                                <td key={cell.id} {...cell.getCellProps()}>
                                  {cell.render("Cell")}
                                </td>
                              )
                            })}
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </div>

                <Row className="justify-content-md-end justify-content-center align-items-center">
                  <Col className="col-md-auto">
                    <div className="d-flex gap-1">
                      <Button
                        color="primary"
                        onClick={previousPage}
                        disabled={!canPreviousPage}
                      >
                        {"<"}
                      </Button>
                    </div>
                  </Col>
                  <Col className="col-md-auto d-none d-md-block">
                    Page <strong>{pageIndex + 1}</strong>
                  </Col>

                  <Col className="col-md-auto">
                    <div className="d-flex gap-1">
                      <Button
                        color="primary"
                        onClick={nextPage}
                        disabled={!canNextPage}
                      >
                        {">"}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Fragment>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(GenerateCode)
