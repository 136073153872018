import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
} from "reactstrap"
import { useParams } from "react-router"
import { useQuery, useMutation } from "react-query"
import { Link, useNavigate } from "react-router-dom"
import Swal from "sweetalert2"
import withRouter from "components/Common/withRouter"

import { GENERAL } from "api/general"
//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"
// actions
import userProfileImg from "assets/images/user_profile_image.png"

const UserProfile = () => {
  //meta title
  document.title = "Profile -"
  const history = useNavigate()
  const params = useParams()
  const [show, setShow] = useState(false)
  const [reason, setReason] = useState(null)
  const [agentRecord, setAgentRecord] = useState(null)

  const getRecordByNpcId = useQuery({
    queryKey: ["getRecordByNpcId"],
    queryFn: () => {
      Swal.fire({
        title: "Loading...",
        showConfirmButton: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading(Swal.getDenyButton())
        },
      })
      return GENERAL.getRecordByNpcId({ access_code: params.npc_id })
    },
    onSuccess: async res => {
      Swal.close()
      if (res?.status === 201) {
        setAgentRecord(res?.data?.palliative)
      }
    },
  })

  const deleteAgent = () => {
    // show delete modal
    setShow(true)
  }

  const closeModalFn = () => {
    setShow(false)
  }

  const handleDeleteAgentFn = () => {
    if (reason) {
      setShow(false)
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete agent!",
      }).then(result => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Rejecting agent...",
            showConfirmButton: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading(Swal.getDenyButton())
            },
          })

          const deleteAgentDto = {
            code: agentRecord?.access_code,
            comment: reason,
          }
          // call delete agent mutation
          deleteAgentFn.mutate(deleteAgentDto)
        }
      })
    } else {
      Swal.fire("Process Failed", "Reason cannot be empty", "error")
    }
  }

  const deleteAgentFn = useMutation({
    mutationFn: payload => {
      return GENERAL.deleteAgentFn(payload)
    },
    onSuccess: async res => {
      Swal.close()
      console.log("delete-agent response ::", res)
      if (res?.data?.palliative.statusCode == 400) {
        Swal.fire("Process Failed!", res?.data?.palliative.message, "error")
        showModal()
      }
      if (res?.data?.palliative.statusCode == 200) {
        Swal.fire(
          "Process Successful!",
          res?.data?.palliative.message,
          "success"
        ).then(res => {
          if (res.isConfirmed) {
            history(-1)
          }
        })
      }
    },
  })

  return (
    <React.Fragment>
      <Modal size="lg" isOpen={show} centered={true}>
        <ModalBody className="py-3 px-5">
          <Row style={{ paddingTop: "25px", paddingBottom: "25px" }}>
            <Col lg={12} style={{ marginTop: "10px", marginBottom: "10px" }}>
              <div className="">
                <h5 className="text-danger">REJECT AGENT</h5>
              </div>
            </Col>
            <Form>
              <Row>
                <Col md={12}>
                  <div className="mb-3">
                    <Label htmlFor="formrow-password-Input">
                      Reason <span className="text-danger">*</span>
                    </Label>
                    <div className="col-md-12">
                      <Input
                        type="textarea"
                        name="reason"
                        id="reason"
                        placeholder="Enter Reason"
                        height={15}
                        onChange={evt => setReason(evt.target.value)}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </Form>
          </Row>
          <Row>
            <Col xl={6}>
              <button
                type="submit"
                className="btn btn-info w-100"
                onClick={handleDeleteAgentFn}
              >
                REJECT AGENT
              </button>
            </Col>

            <Col xl={6}>
              <button
                type="submit"
                style={{ marginLeft: 9 }}
                className="btn btn-danger w-100"
                onClick={() => closeModalFn()}
              >
                CANCEL
              </button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb
            title="Agent"
            breadcrumbItem="Profile"
            hasBackBtn={true}
          />

          <Row>
            <Col lg="3">
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <div className="text-center">
                        <div
                          className="text-center"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            // src={userProfileImg}
                            src={`data:image/png;base64,${agentRecord?.face}`}
                            width={300}
                            style={{ textAlign: "center" }}
                            alt=""
                            className="avatar-xl"
                          />
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>

                <Col lg="12">
                  <Card>
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1 text-center">
                          <div className="text-black">
                            <h5>Application Status</h5>
                            <p
                              className={`badge bg-${
                                agentRecord?.status === 0
                                  ? "warning"
                                  : agentRecord?.status === 1
                                  ? "primary"
                                  : agentRecord?.status === 2
                                  ? "danger"
                                  : ""
                              } mb-1 p-2`}
                            >
                              {agentRecord?.status === 0
                                ? "Pending"
                                : agentRecord?.status === 1
                                ? "Approved"
                                : agentRecord?.status === 2
                                ? "Declined"
                                : ""}
                            </p>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>

                <Col lg="12">
                  <Card>
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1 text-center">
                          <div className="text-black">
                            <h5>Face Match Score</h5>
                            <p className="badge bg-success mb-1 p-2">
                              {Number(agentRecord?.face_score).toFixed(2)}%
                            </p>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>

                <Col lg="12">
                  <Card>
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1 text-center">
                          <div className="text-black">
                            <h5>Application ID</h5>
                            <p className="mb-1">{agentRecord?.access_code}</p>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col lg="9">
              {/* {error && error ? <Alert color="danger">{error}</Alert> : null}
              {success ? <Alert color="success">{success}</Alert> : null} */}
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <div className="text-black">
                        <h5>BIO DATA INFORMATION</h5>
                      </div>

                      <Row>
                        <Col lg="3" className="mt-5">
                          <h5>Surname</h5>
                          <p className="mb-1">
                            {agentRecord?.bio_data?.lastname}
                          </p>
                        </Col>
                        <Col lg="3" className="mt-5">
                          <h5>Middle Name</h5>
                          <p className="mb-1">
                            {agentRecord?.bio_data?.middlename
                              ? agentRecord?.bio_data?.middlename
                              : "---"}
                          </p>
                        </Col>
                        <Col lg="3" className="mt-5">
                          <h5>First Name</h5>
                          <p className="mb-1">
                            {agentRecord?.bio_data?.firstname}
                          </p>
                        </Col>

                        <Col lg="3" className="mt-5">
                          <h5>Date Of Birth</h5>
                          <p className="mb-1">{agentRecord?.bio_data?.dob}</p>
                        </Col>

                        <Col lg="3" className="mt-5">
                          <h5>State Of Origin</h5>
                          <p className="mb-1">{agentRecord?.bio_data?.state}</p>
                        </Col>

                        <Col lg="3" className="mt-5">
                          <h5>LGA Of Origin</h5>
                          <p className="mb-1">{agentRecord?.bio_data?.lga}</p>
                        </Col>

                        <Col lg="3" className="mt-5">
                          <h5>
                            Difficulties In Performing Any Of The Following:
                          </h5>
                          <p className="mb-1">
                            {agentRecord?.bio_data?.physical_challenge}
                          </p>
                        </Col>

                        <Col lg="3" className="mt-5">
                          <h5>NIN</h5>
                          <p className="mb-1">
                            {agentRecord?.nin}
                          </p>
                        </Col>

                        <Col lg="3" className="mt-5">
                          <h5>Sex</h5>
                          <p className="mb-1">
                            {agentRecord?.bio_data?.gender}
                          </p>
                        </Col>

                        <Col lg="3" className="mt-5">
                          <h5>Any Other Language</h5>
                          <p className="mb-1">
                            {agentRecord?.bio_data?.other_language
                              ? agentRecord?.bio_data?.other_language
                              : "---"}
                          </p>
                        </Col>

                        <Col lg="3" className="mt-5">
                          <h5>
                            Have You Ever Been Convicted For Any Criminal Case
                          </h5>
                          <p className="mb-1">
                            {agentRecord?.bio_data?.criminal_record}
                          </p>
                        </Col>

                        <Col lg="3" className="mt-5">
                          <h5>Computer Proficiency</h5>
                          <p className="mb-1">
                            {agentRecord?.bio_data?.computer_skill
                              ? agentRecord?.bio_data?.computer_skill
                              : "---"}
                          </p>
                        </Col>

                        <Col lg="3" className="mt-5">
                          <h5>Language Proficiency</h5>
                          <p className="mb-1">
                            {agentRecord?.bio_data?.language}
                          </p>
                        </Col>

                        <Col lg="3" className="mt-5">
                          <h5>Next Of Kin *</h5>
                          <p className="mb-1">
                            {agentRecord?.bio_data?.next_kin_name}
                          </p>
                        </Col>

                        <Col lg="3" className="mt-5">
                          <h5>Next Kin Phone Number</h5>
                          <p className="mb-1">
                            {agentRecord?.bio_data?.next_kin_phone}
                          </p>
                        </Col>

                        <Col lg="3" className="mt-5">
                          <h5>Relationship With Next Of Kin</h5>
                          <p className="mb-1">
                            {agentRecord?.bio_data?.next_kin_relationship}
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Card>
            <CardBody>
              <div className="d-flex">
                <div className="flex-grow-1">
                  <div className="text-black">
                    <h5>PLACE OF PERMANENT RESIDENCE</h5>
                  </div>

                  <Row>
                    <Col lg="3" className="mt-5">
                      <h5>State Of Residence</h5>
                      <p className="mb-1">
                        {agentRecord?.residence?.state_resid}
                      </p>
                    </Col>

                    <Col lg="3" className="mt-5">
                      <h5>LGA Of Residence</h5>
                      <p className="mb-1">
                        {agentRecord?.residence?.lga_resid}
                      </p>
                    </Col>

                    <Col lg="3" className="mt-5">
                      <h5>Registration Centre</h5>
                      <p className="mb-1">{agentRecord?.residence?.ward}</p>
                    </Col>

                    <Col lg="5" className="mt-5">
                      <h5>Usual Residential Address</h5>
                      <p className="mb-1">{agentRecord?.residence?.address}</p>
                    </Col>
                  </Row>
                </div>
              </div>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <div className="d-flex">
                <div className="flex-grow-1">
                  <div className="text-black">
                    <h5>VALIDATED CONTACT ADDRESS</h5>
                  </div>

                  <Row>
                    <Col lg="3" className="mt-5">
                      <h5>Email Address</h5>
                      <p className="mb-1">{agentRecord?.contact?.email}</p>
                    </Col>
                    <Col lg="3" className="mt-5">
                      <h5>Mobile Number</h5>
                      <p className="mb-1">{agentRecord?.contact?.phone}</p>
                    </Col>
                  </Row>
                </div>
              </div>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <div className="d-flex">
                <div className="flex-grow-1">
                  <div className="text-black">
                    <h5>BANK DETAILS</h5>
                  </div>

                  <Row>
                    <Col lg="3" className="mt-5">
                      <h5>Bank Name</h5>
                      <p className="mb-1">{agentRecord?.bank?.bank_name}</p>
                    </Col>
                    <Col lg="3" className="mt-5">
                      <h5>Account Number</h5>
                      <p className="mb-1">
                        {agentRecord?.bank?.account_number}
                      </p>
                    </Col>
                    <Col lg="3" className="mt-5">
                      <h5>Account Name</h5>
                      <p className="mb-1">{agentRecord?.bank?.account_name}</p>
                    </Col>
                  </Row>
                </div>
              </div>
            </CardBody>
          </Card>
          {/* <div className="float-end"> */}
          <Row>
            <div style={{ margin: 20, alignItems: "end" }}>
              <button
                type="submit"
                className="btn btn-danger w-md"
                style={{ marginRight: 25 }}
                onClick={deleteAgent}
              >
                Reject
              </button>
              <button
                type="submit"
                className="btn btn-info w-md"
                onClick={() => {
                  history(-1)
                }}
              >
                Back
              </button>
            </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(UserProfile)
