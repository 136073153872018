import React from "react"
import { Col, Card, CardBody, CardTitle } from "reactstrap"
import ReactApexChart from "react-apexcharts"
import getChartColorsArray from "../../components/Common/ChartsDynamicColor"

const BarChart = ({ dataColors, periodData }) => {
  const spineareaChartColors = getChartColorsArray(dataColors);

  const series = [
    {
      data: periodData,
    },
  ];

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: true,
    },
    colors: spineareaChartColors,
    grid: {
      borderColor: ["#f1f1f1", "#000000"],
    },
    xaxis: {
      categories: ["Approved", "Rejected"],
    },
    tooltip: {
      custom: function({ series, seriesIndex, dataPointIndex, w }) {
        if (seriesIndex === 0) {
          return '';
        }
        return undefined;
      },
    },
  };

  return (
    <React.Fragment>
      <Col xl="12">
        <Card>
          <CardBody>
            <CardTitle className="mb-4">Approved and rejected applications statistics</CardTitle>
            <ReactApexChart
              options={options}
              series={series}
              type="bar"
              height="150"
            />
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
}

export default BarChart;