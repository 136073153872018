import React, { useState } from "react"

import { Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"

import avatar1 from "../../assets/images/users/avatar-1.jpg"
import profileImg from "../../assets/images/profile-img.png"
import coat from "../../assets/images/coat_of_arms.png"

const WelcomeComp = () => {
  const [userInfo, setUserInfo] = useState(
    JSON.parse(localStorage.getItem("authUser"))
  )
  const [roleInfo, setRoleInfo] = useState(
    JSON.parse(localStorage.getItem("userRole"))
  )
  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="bg-primary bg-soft">
          <Row>
            <Col xs="7">
              <div className="text-primary p-3">
                <h5 className="text-primary" style={{ marginBottom: 0 }}>
                  Welcome Back !
                </h5>
              </div>
            </Col>
            <Col xs="5" className="align-self-end">
              <img src={profileImg} alt="" className="img-fluid" />
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row>
            <Col sm="12">
              <div className="avatar-md profile-user-wid">
                <img
                  src={coat}
                  alt=""
                  className="img-thumbnail rounded-circle"
                />
              </div>
              <p className="font-size-14 d-flex justify-content-end">
                {userInfo.email}
              </p>

              <h5 className="font-size-15">
                <div>
                  {roleInfo === "SUPER_ADMIN" ? (
                    <div>
                      {userInfo.firstname} {userInfo.lastname} (Super Admin)
                    </div>
                  ) : roleInfo === "LGA_SUPERVISOR" ||
                    roleInfo === "NIMC_STAFF" ? (
                    <div>
                      {userInfo.firstname} {userInfo.lastname} (
                      {userInfo.lgaName}, {userInfo.stateName})
                    </div>
                  ) : roleInfo === "REGIONAL_SUPERVISOR" ? (
                    <div>
                      {userInfo.firstname} {userInfo.lastname} (
                      {userInfo.zoneName})
                    </div>
                  ) : (
                    <div>
                      {userInfo.firstname} {userInfo.lastname} (
                      {userInfo.stateName})
                    </div>
                  )}
                </div>
              </h5>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
export default WelcomeComp
