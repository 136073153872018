import React from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import abiaStateLogo from "../../../../src/assets/images/states/Abia_State_Logo.png"
import adamawaStateLogo from "../../../../src/assets/images/states/Adamawa_State_Logo.png"
import akwaIbomStateLogo from "../../../../src/assets/images/states/Akwa_Ibom_State_Logo.png"
import AnambraStateLogo from "../../../../src/assets/images/states/Anambra_State_Logo.png"
import BauchiStateLogo from "../../../../src/assets/images/states/Bauchi_State_Logo.png"
import BayelsaStateLogo from "../../../../src/assets/images/states/Bayelsa_State_Logo.png"
import BenueStateLogo from "../../../../src/assets/images/states/Benue_State_Logo.png"
import BornoStateLogo from "../../../../src/assets/images/states/Borno_State_Logo.png"
import CrossRiverStateLogo from "../../../../src/assets/images/states/Cross_River_State_Logo.png"
import DeltaStateLogo from "../../../../src/assets/images/states/Delta_State_Logo.png"
import EbonyiStateLogo from "../../../../src/assets/images/states/Ebonyi_State_Logo.png"
import EdoStateLogo from "../../../../src/assets/images/states/Edo_State_Logo.png"
import EkitiStateLogo from "../../../../src/assets/images/states/Ekiti_State_Logo.png"
import EnuguStateLogo from "../../../../src/assets/images/states/Enugu_State_Logo.png"
import FCTStateLogo from "../../../../src/assets/images/states/FCT.png"

//i18n
import { withTranslation } from "react-i18next"
const RejectedAgentRegistrationsByState = props => {
  //meta title
  document.title = "Agents Registration - eRecruitement"
  const data = [
    {
      logo: abiaStateLogo,
      name: "ABIA",
    },
    {
      logo: adamawaStateLogo,
      name: "adamawa",
    },
    {
      logo: akwaIbomStateLogo,
      name: "Akwa Ibom",
    },
    {
      logo: AnambraStateLogo,
      name: "Anambra",
    },
    {
      logo: BauchiStateLogo,
      name: "Bauchi",
    },
    {
      logo: BayelsaStateLogo,
      name: "Bayelsa",
    },
    {
      logo: BenueStateLogo,
      name: "Benue",
    },
    {
      logo: BornoStateLogo,
      name: "Borno",
    },
    {
      logo: CrossRiverStateLogo,
      name: "Cross River",
    },
    {
      logo: DeltaStateLogo,
      name: "Delta",
    },
    {
      logo: EbonyiStateLogo,
      name: "Ebonyi",
    },
    {
      logo: EdoStateLogo,
      name: "Edo",
    },
    {
      logo: EkitiStateLogo,
      name: "Ekiti",
    },
    {
      logo: EnuguStateLogo,
      name: "Enugu",
    },
    {
      logo: FCTStateLogo,
      name: "FCT",
    },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Rejected Agent Registrations")}
            breadcrumbItem={props.t("Rejected Agent Registrations By State")}
          />

          <Row>
            {data.map((item, key) => (
              <Col key={"_col_" + key} md="3">
                <Card className="mini-stats-wid">
                  <CardBody
                    style={{
                      paddingTop: 40,
                      paddingBottom: 40,
                      paddingLeft: 10,
                      paddingRight: 10,
                    }}
                  >
                    <div className="text-center mb-4">
                      <img
                        src={item.logo}
                        width={100}
                        height={100}
                        style={{ textAlign: "center" }}
                      />

                      <div className="row justify-content-center mt-3">
                        <div className="col-xl-10">
                          <h4 className="text-primary">{item.name}</h4>
                        </div>
                      </div>
                    </div>

                    <hr />
                    <Row
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Col md="5">
                        <Row>
                          <h5
                            style={{
                              textAlign: "center",
                              fontSize: "1rem",
                              fontWeight: "bold",
                              color: "#000",
                            }}
                          >
                            Threshold
                          </h5>
                          <h6
                            style={{
                              textAlign: "center",
                              fontSize: "0.9rem",
                              fontWeight: "500",
                              color: "#000",
                            }}
                          >
                            1,000
                          </h6>
                        </Row>
                      </Col>
                      <Col md="7">
                        <Row>
                          <h5
                            style={{
                              textAlign: "center",
                              fontSize: "1rem",
                              fontWeight: "bold",
                              color: "#000",
                            }}
                          >
                            Total Applications
                          </h5>
                          <h6
                            style={{
                              textAlign: "center",
                              fontSize: "0.9rem",
                              fontWeight: "500",
                              color: "#000",
                            }}
                          >
                            6,450
                          </h6>
                        </Row>
                      </Col>
                    </Row>
                    <hr />

                    <Row
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Col>
                        <h5
                          style={{
                            textAlign: "center",
                            fontSize: "0.9rem",
                            fontWeight: "500",
                            color: "#000",
                          }}
                        >
                          <Link to={"/rejected-agent-registrations-by-lga"}>
                            View LGAs
                          </Link>
                        </h5>
                      </Col>
                      <Col>
                        <h5
                          style={{
                            textAlign: "center",
                            fontSize: "0.9rem",
                            fontWeight: "500",
                            color: "#000",
                          }}
                        >
                          <Link to={"/view-rejected-agent-registrations-by-state"}>
                            View Agents
                          </Link>
                        </h5>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(RejectedAgentRegistrationsByState)
