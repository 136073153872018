import PropTypes from "prop-types"
import React, { Fragment, useState } from "react"

import {
  Row,
  Col,
  Alert,
  Container,
  Form,
  Input,
  InputGroup,
  FormFeedback,
  Label,
} from "reactstrap"
import CarouselPage from "./CarouselPage"
import { Link, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { useMutation } from "react-query"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"
import Swal from "sweetalert2"

// import images
import logodark from "../../assets/images/coat_of_arms.png"
import logolight from "../../assets/images/coat_of_arms.png"
import { AUTH } from "../../api/auth"

const ForgotPassword = props => {
  const navigate = useNavigate()
  const [showPassword, setshowPassword] = useState(false)
  const [showPassword2, setshowPassword2] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [successMessage, setSuccessMessage] = useState(false)

  const [email, setEmail] = useState("")
  const [showEmailScreen, setShowEmailScreen] = useState(true)
  const [showOtpScreen, setShowOtpScreen] = useState(false)
  const [showNewPwdScreen, setShowNewPwdScreen] = useState(false)
  //meta title
  document.title = "Forgot Password | Palliative - e-Recruitment Portal"

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
    }),
    onSubmit: values => {
      setEmail(values.email)
      validateEmail.mutate(values)
    },
  })

  const validateEmail = useMutation({
    mutationFn: payload => {
      return AUTH.requestPasswordOtp(payload)
    },

    onSuccess: async res => {
      if (res?.data?.palliative?.status === 1) {
        Swal.fire(
          "Process successful",
          res.data.palliative.message,
          "success"
        ).then(res => {
          if (res.isConfirmed) {
            setShowEmailScreen(false)
            setShowOtpScreen(true)
          }
        })
      }
      if (res?.data?.palliative?.status === 0) {
        Swal.fire("Process failed", res?.data?.palliative?.message, "error")
      }
    },
  })

  const otpValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      otp: "",
    },
    validationSchema: Yup.object({
      otp: Yup.number().required("Enter otp"),
    }),
    onSubmit: values => {
      const validateOtpDto = {
        email: email,
        otp: String(values.otp),
      }
      validateOtp.mutate(validateOtpDto)
    },
  })

  const validateOtp = useMutation({
    mutationFn: payload => {
      return AUTH.validateOtp(payload)
    },

    onSuccess: async res => {
      console.log("validate otp response :: ", res)
      if (res.data.palliative.status === 1) {
        Swal.fire(
          "Process successful",
          res.data.palliative.message,
          "success"
        ).then(res => {
          if (res.isConfirmed) {
            setShowEmailScreen(false)
            setShowOtpScreen(false)
            setShowNewPwdScreen(true)
          }
        })
      }
    },
  })

  const pwdValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Enter new password"),
      confirmPassword: Yup.string().required("Enter confirm password"),
    }),
    onSubmit: values => {
      console.log(email);

      if (values.password != values.confirmPassword) {
        Swal.fire("Password and Confirm Password must be equal", "", "error")
      } else {
        const resetPwdDto = {
          password: values.password,
          confirm: values.confirmPassword,
          email: email
        }
        resetPassword.mutate(resetPwdDto)
      }
    },
  })

  const resetPassword = useMutation({
    mutationFn: payload => {
      return AUTH.resetPwd(payload)
    },

    onSuccess: async res => {
      console.log("reset pwd response :: ", res)
      if (res?.data?.palliative?.statusCode === 200) {
        Swal.fire(
          "Process successful",
          res.data.palliative.message,
          "success"
        ).then(res => {
          if (res.isConfirmed) {
            navigate(`/login`)
          }
        })
      }

      if (res?.status == 422) {
        Swal.fire("Process failed", res.data.message.join(", "), "error")
      }
    },
  })

  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <CarouselPage />

            <Col xl={3}>
              <div className="auth-full-page-content p-md-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="my-auto ">
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <div
                          className="mb-4 mb-md-5"
                          style={{
                            top: "177px",
                            left: "1520px",
                            width: "99px",
                            height: "99px",
                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                            boxShadow: "0px 3px 6px #00000029",
                            opacity: 1,
                            borderRadius: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "center",
                          }}
                        >
                          <Link to="/dashboard" className="d-block auth-logo">
                            <img
                              src={logodark}
                              alt=""
                              height="75"
                              className="logo-dark-element"
                            />
                            <img
                              src={logolight}
                              alt=""
                              height="75"
                              className="logo-light-element"
                            />
                          </Link>
                        </div>
                      </div>
                      <div>
                        <h3 className="text-primary">
                          Federal Government Of Nigeria
                        </h3>
                        <p
                          className="text-muted"
                          style={{ fontSize: 18, textAlign: "center" }}
                        >
                          Palliative E - Recruitment Dashboard
                        </p>
                      </div>

                      <div className="mt-4">
                        {showEmailScreen && (
                          <Fragment>
                            <h5 className="text-center">Forgot Password</h5>
                            <Form
                              className="form-horizontal"
                              onSubmit={e => {
                                e.preventDefault()
                                validation.handleSubmit()
                                return false
                              }}
                            >
                              {showErrorMessage ? (
                                <Alert color="danger">{errorMessage}</Alert>
                              ) : null}
                              {showSuccessMessage ? (
                                <Alert color="primary">{successMessage}</Alert>
                              ) : null}

                              <div className="mb-3">
                                <Label className="form-label">Email</Label>
                                <Input
                                  name="email"
                                  className="form-control"
                                  placeholder="Enter email"
                                  type="email"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.email || ""}
                                  invalid={
                                    validation.touched.email &&
                                    validation.errors.email
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.email &&
                                validation.errors.email ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.email}
                                  </FormFeedback>
                                ) : null}
                              </div>

                              <div className="mt-3 d-grid">
                                {validateEmail.isLoading && (
                                  <button
                                    className="btn btn-primary btn-block"
                                    type="submit"
                                  >
                                    Processing
                                  </button>
                                )}
                                {!validateEmail.isLoading && (
                                  <button
                                    className="btn btn-primary btn-block"
                                    type="submit"
                                  >
                                    Continue
                                  </button>
                                )}

                                {/* <button
                                  className="btn btn-primary btn-block"
                                  type="submit"
                                >
                                  Continue
                                </button> */}
                              </div>

                              <div className="mt-4 text-center">
                                <Link to="/login" className="text-muted">
                                  <i className="mdi mdi-lock me-1" />
                                  Back to log in
                                </Link>
                              </div>
                            </Form>
                          </Fragment>
                        )}

                        {showOtpScreen && (
                          <Fragment>
                            <h5 className="text-center">OTP</h5>
                            <p className="text-center">
                              An OTP has been sent to your email address
                            </p>
                            <Form
                              className="form-horizontal"
                              onSubmit={e => {
                                e.preventDefault()
                                otpValidation.handleSubmit()
                                return false
                              }}
                            >
                              <div className="mb-3">
                                <Label className="form-label">OTP</Label>
                                <Input
                                  name="otp"
                                  className="form-control"
                                  placeholder="Enter otp"
                                  type="number"
                                  onChange={otpValidation.handleChange}
                                  onBlur={otpValidation.handleBlur}
                                  value={otpValidation.values.otp || ""}
                                  invalid={
                                    otpValidation.touched.otp &&
                                    otpValidation.errors.otp
                                      ? true
                                      : false
                                  }
                                />
                                {otpValidation.touched.otp &&
                                otpValidation.errors.otp ? (
                                  <FormFeedback type="invalid">
                                    {otpValidation.errors.otp}
                                  </FormFeedback>
                                ) : null}
                              </div>

                              <div className="mt-3 d-grid">
                                {validateOtp.isLoading && (
                                  <button
                                    className="btn btn-primary btn-block"
                                    type="submit"
                                  >
                                    Validating OTP...
                                  </button>
                                )}
                                {!validateOtp.isLoading && (
                                  <button
                                    className="btn btn-primary btn-block"
                                    type="submit"
                                  >
                                    Continue
                                  </button>
                                )}

                                {/* <button
                                  className="btn btn-primary btn-block"
                                  type="submit"
                                >
                                  Continue
                                </button> */}
                              </div>

                              <div className="mt-4 text-center">
                                <Link to="/login" className="text-muted">
                                  <i className="mdi mdi-lock me-1" />
                                  Back to log in
                                </Link>
                              </div>
                            </Form>
                          </Fragment>
                        )}

                        {showNewPwdScreen && (
                          <Fragment>
                            <h5 className="text-center">New Password</h5>
                            {/* <p className="text-center">An OTP has been sent to your email address</p> */}
                            <Form
                              className="form-horizontal"
                              onSubmit={e => {
                                e.preventDefault()
                                pwdValidation.handleSubmit()
                                return false
                              }}
                            >
                              <div className="mb-3">
                                <Label className="form-label">Password</Label>

                                <InputGroup>
                                  <Input
                                    name="password"
                                    value={pwdValidation.values.password || ""}
                                    type={`${
                                      showPassword ? "text" : "password"
                                    }`}
                                    placeholder="Enter Password"
                                    onChange={pwdValidation.handleChange}
                                    onBlur={pwdValidation.handleBlur}
                                    invalid={
                                      pwdValidation.touched.password &&
                                      pwdValidation.errors.password
                                        ? true
                                        : false
                                    }
                                  />
                                  <div className="input-group-append">
                                    <span className="input-group-text">
                                      <i
                                        onClick={() =>
                                          setshowPassword(!showPassword)
                                        }
                                        className={`mdi mdi-${
                                          !showPassword ? "eye-off" : "eye"
                                        }-outline`}
                                      />
                                    </span>
                                  </div>
                                </InputGroup>
                                {pwdValidation.touched.password &&
                                pwdValidation.errors.password ? (
                                  <FormFeedback type="invalid">
                                    {pwdValidation.errors.password}
                                  </FormFeedback>
                                ) : null}
                              </div>

                              <div className="mb-3">
                                <Label className="form-label">
                                  Confirm Password
                                </Label>

                                <InputGroup>
                                  <Input
                                    name="confirmPassword"
                                    value={
                                      pwdValidation.values.confirmPassword || ""
                                    }
                                    type={`${
                                      showPassword2 ? "text" : "password"
                                    }`}
                                    placeholder="Confirm Password"
                                    onChange={pwdValidation.handleChange}
                                    onBlur={pwdValidation.handleBlur}
                                    invalid={
                                      pwdValidation.touched.confirmPassword &&
                                      pwdValidation.errors.confirmPassword
                                        ? true
                                        : false
                                    }
                                  />
                                  <div className="input-group-append">
                                    <span className="input-group-text">
                                      <i
                                        onClick={() =>
                                          setshowPassword2(!showPassword2)
                                        }
                                        className={`mdi mdi-${
                                          !showPassword2 ? "eye-off" : "eye"
                                        }-outline`}
                                      />
                                    </span>
                                  </div>
                                </InputGroup>
                                {pwdValidation.touched.confirmPassword &&
                                pwdValidation.errors.confirmPassword ? (
                                  <FormFeedback type="invalid">
                                    {pwdValidation.errors.confirmPassword}
                                  </FormFeedback>
                                ) : null}
                              </div>

                              <div className="mt-3 d-grid">
                                {resetPassword.isLoading && (
                                  <button
                                    className="btn btn-primary btn-block"
                                    type="submit"
                                  >
                                    Resetting Password...
                                  </button>
                                )}
                                {!resetPassword.isLoading && (
                                  <button
                                    className="btn btn-primary btn-block"
                                    type="submit"
                                  >
                                    Continue
                                  </button>
                                )}

                                {/* <button
                                  className="btn btn-primary btn-block"
                                  type="submit"
                                >
                                  Continue
                                </button> */}
                              </div>

                              <div className="mt-4 text-center">
                                <Link to="/login" className="text-muted">
                                  <i className="mdi mdi-lock me-1" />
                                  Back to log in
                                </Link>
                              </div>
                            </Form>
                          </Fragment>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(ForgotPassword)

ForgotPassword.propTypes = {
  history: PropTypes.object,
}
