import React from "react"
import { Navigate } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
// import Login from "../pages/Authentication/Login2"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

import TotalAgentRegistrationsByState from "../pages/TotalAgentRegistration/TotalAgentRegistrationsByState/index"
import TotalAgentRegistrationsByLga from "../pages/TotalAgentRegistration/TotalAgentRegistrationsByLga/index"
import ViewTotalAgentRegistrationsByState from "../pages/TotalAgentRegistration/ViewTotalAgentRegistrationsByState/index"
import ViewTotalAgentRegistrationsByLga from "../pages/TotalAgentRegistration/ViewTotalAgentRegistrationsByLga/index"
// pending agent registrations
import PendingAgentRegistrationsByState from "../pages/PendingAgentRegistration/PendingAgentRegistrationsByState/index"
import PendingAgentRegistrationsByLga from "../pages/PendingAgentRegistration/PendingAgentRegistrationsByLga/index"
import ViewPendingAgentRegistrationsByState from "../pages/PendingAgentRegistration/ViewPendingAgentRegistrationsByState/index"
import ViewPendingAgentRegistrationsByLga from "../pages/PendingAgentRegistration/ViewPendingAgentRegistrationsByLga/index"
// declined agent registrations
import DeclinedAgentRegistrationsByState from "../pages/DeclinedAgentRegistration/DeclinedAgentRegistrationsByState/index"
import DeclinedAgentRegistrationsByLga from "../pages/DeclinedAgentRegistration/DeclinedAgentRegistrationsByLga/index"
import ViewDeclinedAgentRegistrationsByState from "../pages/DeclinedAgentRegistration/ViewDeclinedAgentRegistrationsByState/index"
import ViewDeclinedAgentRegistrationsByLga from "../pages/DeclinedAgentRegistration/ViewDeclinedAgentRegistrationsByLga/index"
// rejected agent registrations
import RejectedAgentRegistrationsByState from "../pages/RejectedAgentRegistration/RejectedAgentRegistrationsByState/index"
import RejectedAgentRegistrationsByLga from "../pages/RejectedAgentRegistration/RejectedAgentRegistrationsByLga/index"
import ViewRejectedAgentRegistrationsByState from "../pages/RejectedAgentRegistration/ViewRejectedAgentRegistrationsByState/index"
import ViewRejectedAgentRegistrations from "../pages/RejectedAgentRegistration/ViewRejectedAgentRegistrations/index"
import ViewRejectedAgentRegistrationsByLga from "../pages/RejectedAgentRegistration/ViewRejectedAgentRegistrationsByLga/index"

import ApprovedAgentRegistrations from "../pages/ApprovedAgentRegistration/ApprovedAgentRegistrations/index"
import ApprovedAgentRegistrationsByLga from "../pages/ApprovedAgentRegistration/ApprovedAgentRegistrationsByLga/index"
import ViewApprovedAgentRegistrationsByState from "../pages/ApprovedAgentRegistration/ViewApprovedAgentRegistrationsByState/index"
import ViewApprovedAgentRegistrationsByStateId from "../pages/ApprovedAgentRegistration/ViewApprovedAgentRegistrationsByState/get-approved-states-by-id"
import ViewApprovedAgentRegistrationsByLga from "../pages/ApprovedAgentRegistration/ViewApprovedAgentRegistrationsByLga/index"
import ViewApprovedAgentRegistrationsByLga2 from "../pages/ApprovedAgentRegistration/ViewApprovedAgentRegistrationsByLga2/index"
// approved-nimc-supervisors
import ApprovedNIMCSupervisorsByState from "../pages/ApprovedNIMCSupervisors/ApprovedNIMCSupervisorsByState/index"
import ApprovedNIMCSupervisorsByLga from "../pages/ApprovedNIMCSupervisors/ApprovedNIMCSupervisorsByLga/index"
import ViewApprovedNIMCSupervisorsByState from "../pages/ApprovedNIMCSupervisors/ViewApprovedNIMCSupervisorsByState/index"
import ViewApprovedNIMCSupervisorsByLga from "../pages/ApprovedNIMCSupervisors/ViewApprovedNIMCSupervisorsByLga/index"
// training records
import TrainingRecordsByState from "../pages/TrainingRecords/TrainingRecordsByState/index"
import TrainingRecordsByLga from "../pages/TrainingRecords/TrainingRecordsByLga/index"
import ViewTrainingRecordsByState from "../pages/TrainingRecords/ViewTrainingRecordsByState/index"
import ViewTrainingRecordsByLga from "../pages/TrainingRecords/ViewTrainingRecordsByLga/index"
// generate code
import GenerateCode from "../pages/GenerateCode/index"
import UserList from "pages/ManageUsers/UserList"
import AgentProfile from "pages/ManageUsers/UserProfile"

import ViewApprovedAgentsByLga from "pages/Agents/ViewApprovedAgentsByLga"
import ViewPendingAgentsByLga from "pages/Agents/ViewPendingAgentsByLga"
import ViewTrainingAttendanceByLga from "pages/TrainingAttendance/ViewTrainingAttendanceByLga"
import ForgotPassword from "pages/Authentication/ForgotPassword"
import SearchResult from "pages/SearchResult"
import ViewPendingAgentRegistrationsByLgaNimcStaff from "pages/PendingAgentRegistration/ViewPendingAgentRegistrationsByLgaNimcStaff"
import ViewDeclinedAgentRegistrationsByLgaNimcStaff from "pages/DeclinedAgentRegistration/ViewDeclinedAgentRegistrationsByLgaNimcStaff"
import ViewApprovedAgentRegistrationsByLgaNimc from "pages/ApprovedAgentRegistration/ViewApprovedAgentRegistrationsByLgaNimc"

//state records

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  // total agent registration routes
  {
    path: "/total-agent-registrations-by-state",
    component: <TotalAgentRegistrationsByState />,
  },
  {
    path: "/total-agent-registrations-by-lga/:id/:state",
    component: <TotalAgentRegistrationsByLga />,
  },
  {
    path: "/view-total-agent-registrations-by-state/:id",
    component: <ViewTotalAgentRegistrationsByState />,
  },
  {
    path: "/view-total-agent-registrations-by-lga",
    component: <ViewTotalAgentRegistrationsByLga />,
  },
  // approved agent registration routes
  {
    path: "/approved-agents-registration",
    component: <ApprovedAgentRegistrations />,
    // component: <ViewApprovedAgentRegistrationsByLga />,
  },
  {
    path: "/approved-agent-registrations-by-lga/:id/:state",
    component: <ApprovedAgentRegistrationsByLga />,
  },
  {
    path: "/view-approved-agent-registrations-by-state",
    component: <ViewApprovedAgentRegistrationsByState />,
  },
  {
    path: "/view-approved-agent-registrations-by-state/:id",
    component: <ViewApprovedAgentRegistrationsByStateId />,
  },
  {
    path: "/view-approved-agent-registrations-by-lga/:id",
    component: <ViewApprovedAgentRegistrationsByLga2 />,
  },
  {
    path: "/view-approved-agent-registrations-by-lga",
    component: <ViewApprovedAgentRegistrationsByLga />,
  },
  {
    path: "/view-approved-agent-registrations-by-lga-nimc",
    component: <ViewApprovedAgentRegistrationsByLgaNimc />,
  },
  // pending agent registration routes
  {
    path: "/pending-agent-registrations-by-state",
    component: <PendingAgentRegistrationsByState />,
  },
  {
    path: "/pending-agent-registrations-by-lga/:id/:state",
    component: <PendingAgentRegistrationsByLga />,
  },
  {
    path: "/view-pending-agent-registrations-by-state",
    component: <ViewPendingAgentRegistrationsByState />,
  },
  {
    path: "/view-pending-agent-registrations-by-lga",
    component: <ViewPendingAgentRegistrationsByLga />,
  },
  {
    path: "/view-pending-agent-registrations-by-lga-nimc-staff",
    component: <ViewPendingAgentRegistrationsByLgaNimcStaff />,
  },
  // declined agent registration routes
  {
    path: "/declined-agent-registrations-by-state",
    component: <DeclinedAgentRegistrationsByState />,
  },
  {
    path: "/declined-agent-registrations-by-lga/:id/:state",
    component: <DeclinedAgentRegistrationsByLga />,
  },
  {
    path: "/view-declined-agent-registrations-by-state",
    component: <ViewDeclinedAgentRegistrationsByState />,
  },
  {
    path: "/view-declined-agent-registrations-by-lga",
    component: <ViewDeclinedAgentRegistrationsByLga />,
  },
  {
    path: "/view-declined-agent-registrations-by-lga-nimc-staff",
    component: <ViewDeclinedAgentRegistrationsByLgaNimcStaff />,
  },
  {
    path: "/view-rejected-agent-registrations",
    component: <ViewRejectedAgentRegistrations />,
  },
  {
    path: "/view-rejected-agent-registrations-by-state",
    component: <ViewRejectedAgentRegistrationsByState />,
  },
  {
    path: "/view-rejected-agent-registrations-by-lga",
    component: <ViewRejectedAgentRegistrationsByLga />,
  },
  {
    path: "/approved-nimc-supervisors-by-state",
    component: <ViewApprovedNIMCSupervisorsByLga />,
  },
  {
    path: "/approved-nimc-supervisors-by-lga/:id/:state",
    component: <ApprovedNIMCSupervisorsByLga />,
  },
  {
    path: "/view-approved-nimc-supervisors-by-state",
    component: <ViewApprovedNIMCSupervisorsByState />,
  },
  {
    path: "/view-approved-nimc-supervisors-by-lga",
    component: <ViewApprovedNIMCSupervisorsByLga />,
  },
  // training records routes
  { path: "/training-records-by-state", component: <TrainingRecordsByState /> },
  { path: "/training-records-by-lga/:id/:state", component: <TrainingRecordsByLga /> },
  {
    path: "/view-training-records-by-state/:id",
    component: <ViewTrainingRecordsByState />,
  },
  {
    path: "/view-training-records-by-lga/:id",
    component: <ViewTrainingRecordsByLga />,
  },
  // training records routes
  { path: "/generate-code", component: <GenerateCode /> },

  { path: "/pending-agents-registration", component: <ViewPendingAgentsByLga /> },

  { path: "/training-attendance", component: <ViewTrainingAttendanceByLga /> },

  // //profile
  { path: "/search/:param", component: <SearchResult /> },
  { path: "/profile/:npc_id", component: <UserProfile /> },
  { path: "/manage-users", component: <UserList /> },
  { path: "/user-profile", component: <AgentProfile /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
]

const publicRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  // { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/forgot-password", component: <ForgotPassword /> },
  { path: "/register", component: <Register /> },
]

export { authProtectedRoutes, publicRoutes }
